export default {    
    "header.home": "首页",
    "header.product": "产品中心",
    "header.solution": "解决方案",
    "header.software": "实践案例",
    "header.news": "新闻动态",
    "header.about": "关于我们",
    "header.agent": "代理商",
    "footer.contact": "联系方式",  
    "footer.follow": "关注公众号",  
    "footer.copyright1": "Copyright 2017-2020 南京凯奥思数据技术有限公司.",  
    "footer.copyright2": "版权所有 苏ICP备18005519号",  
    "home.about.introduce.one": "南京凯奥思数据技术(Chaos Data Technology)聚集工业物联网，以工业企业动设备（旋转、往复）在线过程监控保护、智能故障诊断和智慧运维为切入点，结合边缘和云端的机理与大数据建模分析，合理安排设备维护、降低设备停机风险和安全隐患，为工业领域客户提供更加智能、安全、高效的工业互联网产品及解决方案。",
    "home.about.introduce.two": "凯奥思数据核心技术团队均拥有国际一流大学博士学位，在世界500强企业有多年工作经历，项目入选南京市海外高层次人才引进项目、江苏省双创博士。公司技术和产品，在钢铁、化工、汽车制造等多个工业领域获得广泛应用。",
    "home.about.introduce.more": "了解更多>>",
    "home.about.introduce.honor": "荣誉资质",
    "home.about.introduce.memorabilia": "大事记",
    "home.about.introduce.corporate": "企业文化",
    "home.about.introduce.join": "加入我们",
    "home.solution.introduce":"我们为石油化工、煤化工、冶金、LNG等行业的大型旋转和往复式设备提供专业的诊断服务，帮助企业提升生产效率，避免发生潜伏的危机，降低生产运营成本。",
    "home.solution.introduce.chemical": "化工行业",
    "home.solution.introduce.electric": "发电企业",
    "home.solution.introduce.steel": "钢铁行业",
    "home.solution.introduce.car": "汽车行业",
    "home.solution.introduce.track": "轨道交通",
    "home.solution.hardware.title": "硬件产品",
    "home.solution.software.title": "软件产品",
    "home.solution.hardware.p": "凯奥思产品体系支撑您的各类上云业务场景，助您轻松应对智能时代挑战",
    "home.news.title": "公司新闻",
    "home.contact.company": "南京凯奥思技术股份有限公司",
    "home.contact.address": "地址：南京市雨花台区宁双路19号软件谷云密城J栋7楼",
    "home.contact.follow": "关注凯奥思公众号",
    "product.header.title": "国际CTC 传感器",   
    "product.header.content": "国际CTC公司（Connection Technology Center, Inc.）是一家致力于提供高品质设备振动监测与保护产品的高科技公司，其产品涵盖加速度传感器、速度传感器、电涡流振动探头、线缆、连接器、接线箱、信号调理器、振动仪表/继电器以及相关安装测试配件等。",   
    "product.second.title": "详细介绍",   
    "product.second.p1": "国际CTC公司（Connection Technology Center, Inc.）是一家致力于提供多类型高品质的设备振动监测和保护产品的高科技公司，其产品涵盖加速度传感器、速度传感器、电涡流振动探头、高品质线缆、接线箱、信号调理器、振动仪表/继电器以及相关安装测试配件等。",   
    "product.second.p2": "CTC公司是国际联邦振动协会理事单位，凭借安全可靠的硬件性能，十多年的行业经验和无与伦比的质保承诺，目前已成为全球三大振动传感器提供商之一，产品已广泛应用于机械制造、水利水电、石油化工、造纸、风电、工矿、铁路、冶金、水泥、食品加工等需要对设备进行振动监控的工业现场和危险区域。",    
    "product.second.p3": "经过十多年的潜心研发，CTC公司设计出了目前世界上最可靠耐用的振动分析硬件设备，同时拥有行业领先的无条件终生质保服务。",   
    "product.second.p4": "CTC拥有一整套完整的剪切式振动传感器生产线，并采用先进PZT陶瓷材料及密封玻璃全焊接结构，激光焊接技术，使得产品噪声低，可靠性高，可用于潮湿、腐蚀、甚至深水等各种恶劣环境。产品通过了ISO 9001:2008认证，可提供NIST可溯源标定证书。",   
    "product.second.h1.title": "高分辨能力",   
    "product.second.h1.content": "CTC振动传感器极低的噪声与积分电 路使得产品对小幅值的振动信号具有 良好的分辨能力，同时能够清晰、完成的测量和监测低频振动信号",   
    "product.second.h2.title": "精密剪切模式传感结构",   
    "product.second.h2.content": "CTC振动传感器采用的精密PZT陶 瓷剪切模式传感结构确保了准确无干 扰的振动测量双",   
    "product.second.h3.title": "双屏蔽结构",   
    "product.second.h3.content": "CTC振动传感器采用的双屏蔽结构将测量和监测过程中电磁干扰（EMI）和射频干扰（RFI）对振动信号的影响降到最低",   
    "product.second.h4.title": "全不锈钢焊接技术",   
    "product.second.h4.content": "CTC振动传感器坚固的不锈钢外壳和焊接技术确保能够适应您的工厂环境", 
    "product.third.title": "CTC产品",   
    "solution.header.title": "汽车制造解决方案",   
    "solution.header.content": "凯奥思工业互联网平台，包含联接管理平台、工业智能体、工业 应用平台三大部分。定位于做企业增量的智能决策系统，实现业务在云上 敏捷开发，边缘可信运行。赋能行业合作伙伴深耕工业核心业务流，持续释放潜在业务价值。",  
    "solution.second.title": "案例背景",
    "solution.second.li1.strong": "设备种类多、检修任务重：",
    "solution.second.li2.strong": "停产紧急抢修：",
    "solution.second.li3.strong": "复杂的专业故障诊断系统：",
    "solution.second.li4.strong": "大量人力投入：",
    "solution.second.li5.strong": "备品备件囤积：",
    "solution.second.li1": "动力设备种类繁多，点巡检工作量大；点检数据分散，不便统一管理；", 
    "solution.second.li2": "动力设备为企业生产的基础条件，一旦故障极有可能造成停产甚至生产事故；", 
    "solution.second.li3": "传统的振动监测系统要求设备保全人员具备较为专业的知识，且需花费大量时间进行故障判断，随着监控设备的增加极易引发信息灾难；", 
    "solution.second.li4": "非实时的振动检测系统（如手持测振笔、离线振动分析仪等）无法及时获取设备运行状况，周期长、效率低、并且需要大量人力投入；", 
    "solution.second.li5": "传统的定期检修设备过度维护，产生很多不必要的时间、人力以及备品备件的浪费；", 
    "solution.third.title": "解决方案",
    "solution.third.li1": "使用基于FPGA的智能硬件,采集涡轮机和压缩机等重要资产,以及泵、电机等辅助型设备的振动、温度、油压等参数。", 
    "solution.third.li2": "安装了两千多个智能硬件,增加了三万多个传感器,对一万多台设备进行在线监控。", 
    "solution.third.li3": "在六十多个发电厂安装数据服务器,以采集、存储和组各种来源的原始数据和分析结果。", 
    "solution.third.li4": "通过监控中心的数人小组,即可对全集团的核心设备数据进行分析,对故障进行诊断和预测。",
    "solution.fourth.title": "方案架构", 
    "solution.fifth.title": "产品支撑", 
    "solution.fifth.title1": "工业智能边缘终端3500", 
    "solution.fifth.title2": "工业智能边缘终端2700", 
    "solution.fifth.title3": "工业智能边缘终端1900", 
    "solution.fifth.title4": "工业智能传感器", 
    "solution.ul1.li1": "产品特点: 高性能 大通道数 灵活配置", 
    "solution.ul1.li2": "产品用途: 大型旋转或往复式核心动设备", 
    "solution.ul1.li3": "典型场景: 大中型空分设备汽轮机透平机械大中型水轮机发电机组等。",
    "solution.ul2.li1": "产品特点: 高性价比、紧凑型、灵活配置", 
    "solution.ul2.li2": "产品用途: 重要辅机动设备 ", 
    "solution.ul2.li3": "典型场景: 多轴联动机床，冷却塔风扇泵；风机；鼓风机；马达；磨煤机；离心机；混合器；搅拌机；空压机；中型往复式压缩机。",   
    "solution.ul3.li1": "产品特点: 高性价比、紧凑型、固定通道", 
    "solution.ul3.li2": "产品用途: 一般用途的旋转、往复动设备", 
    "solution.ul3.li3": "典型场景: 中高端机床，加工中心，泵机、小型电动机、粉碎机、小型旋转式或往复式压缩机，小型水力涡轮，离心机。",
    "solution.ul4.li1": "产品特点: Wifi+Zigbee无线传输、三年以上寿命", 
    "solution.ul4.li2": "产品用途: 工业现场不适宜布置传感器线缆", 
    "solution.ul4.li3": "产品用途: 钢铁和汽车制造行业生产流水线上电机减速机轴承座等", 
    "solution.sixth.title": "典型客户",
    "software.header.title": "工业智能软件产品",   
    "software.header.content": "对标国际一流系统，参照国际API 670标准设计广泛应用于钢铁、石油、化工等行业的生产设备振动温度等监控机器学习准确识别传感器故障，减少异常停机AI模型边缘部署，自适应调整警报阀值，减少故障漏报误报。",   
    "software.second.title": "边缘计算与存储终端 Chaos Edge",   
    "software.second.p": "本地分析系统是一个工业级，服务中心于工业现场对设备运行数据采集、传输、处理、存储、预警、报警、计算分析，为设备故障诊断和异常排查提供服务中心，通过数据和智能学习算法能够进行设备故障的预测性诊断，部署简单，无需专业IT团队即可快速接入。",   
    "software.second.li1": "支持Linux与Windows 平台部署",   
    "software.second.li2": "支持企业私有云部署，扩容方便", 
    "software.second.li3": "支持Modbus/MQTT/OPC UA 接入第三方数据", 
    "software.second.li4": "所有数据可通过OPC UA方式 访问获取", 
    "news.header.title": "新闻中心",   
    "news.header.content": "对标国际一流系统，参照国际API 670标准设计广泛应用于钢铁、石油、化工等行业的生产设备振动温度等监控机器学习准确识别传感器故障，减少异常停机AI模型边缘部署，自适应调整警报阀值，减少故障漏报误报。",   
    "news.second.title": "行业新闻",   
    "about.header.title": "关于我们",   
    "about.header.content": "聚焦智能制造，专注于动设备的智慧运维为工业领域客户提供更加智能、安全、高效的工业互联网产品及解决方案。",   
    "about.second.p1": "南京凯奥思数据技术(Chaos Data Technology)聚集工业物联网，以工业企业动设备（旋转、往复）在线过程监控保护、智能故障诊断和智慧运维为切入点，结合边缘和云端的机理与大数据建模分析，合理安排设备维护、降低设备停机风险和安全隐患，为工业领域客户提供更加智能、安全、高效的工业互联网产品及解决方案。",   
    "about.second.p2": "凯奥思数据核心技术团队均拥有国际一流大学博士学位，在世界500强企业有多年工作经历，项目入选南京市海外高层次人才引进项目、江苏省双创博士。公司技术和产品，在钢铁、化工、汽车制造等多个工业领域获得广泛应用。",    
    "about.second.p3": "凯奥思数据得到本地政府的大力扶持，是中小型科技企业、双软企业和国家高新技术企业。公司拥有雄厚研发实力，与东南大学、南京信息工程大学、南理工等国内知名高等院校建立长期产学研合作关系。",   
    "about.third.li1.title": "企业愿景", 
    "about.third.li1.content": "凯奥思数据、让工业更智能", 
    "about.third.li2.title": "核心价值观", 
    "about.third.li2.content": "行为标准及行为解读", 
    "about.third.li3.title": "我们的使命", 
    "about.third.li3.content": "为客户提供有竞争力的工业智能化解决方 案和服务，持续为客户创造最大价值。",  
    "about.fourth.li1": "12月  凯奥思杨世飞博士入选南京“创新型企业家培育计划”", 
    "about.fourth.li2": "11月  荣获得国家高新技术企业",  
    "about.fourth.li3": "9月  荣获2019动汇文体国际创投峰会“我为创新产品代言”大赛冠军 荣获第五届“i创杯”互联网创新创业大赛“二等奖”",  
    "about.fourth.li4": "7月  荣获Chaos Date Watch 入选2019江苏省大数据优秀产品 荣获江苏省民营科技企业",  
    "about.fourth.li5": "6月  荣获高新技术企业省入库”",  
    "about.fourth.li6": "5月  入选江苏省i创杯创新创业大赛直通决赛",  
    "about.fourth.li7": "4月  获得ISO 9001质量认证证书获得3A信用等级认证证书", 
    "about.fourth.li8": "3月  获得江苏省软件企业", 
    "about.fourth.li9": "2月  获得南京市高层次创业人才（市级）", 
    "about.fourth.li10": "1月  获得第一届中国（南京）软件谷双创大赛亚军", 
    "agent.header.title": "工业智造解决方案",   
    "agent.header.content": "动设备预测性运维管理", 
    "agent.header.content": "智能制造发展进程中关键基石",  
    "agent.header.content": "精细化解读生产设备运行状态，实时预测性汇报设备故障。",    
    "agent.header.content": "准确掌控生产设备全生命周期，实现生产精益管理。",  
    "agent.header.content": "为工业制造企业打造本地化知识体系，迈向智能制造新时代。",   
    "agent.header.content": "核心优势",   
    "agent.header.content": "国家政策支持应用企业",  
    "agent.header.content": "40余年2000000+台设备运行经验与积累", 
    "agent.header.content": "全球顶尖科学家团队", 
    "agent.header.content": "技术对标国际品牌，成本降低85%", 
    "agent.header.content": "目标行业", 
    "agent.header.content": "钢铁行业", 
    "agent.header.content": "当前已落地客户", 
        
};

