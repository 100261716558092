/**
 * @description 产品
 */
import React, { memo, useCallback,useState,useEffect,useRef} from "react";
import { Link } from "react-router-dom";
import news1 from '../../img/news1.jpg';
import { axiosInstance,baseUrl} from "../../utils/axiosInstance";
import TopMenu from '../../component/header.jsx';
import Footer from '../../component/footer.jsx';
import { Layout,Tabs} from 'antd';
import "./news.css";
import { RightOutlined } from '@ant-design/icons';
const { Content } = Layout;
const { TabPane } = Tabs;

const NewsItem = memo((props) => {
	const [newsItem, setNewsItem] = useState(null);
	const imgSrc = useRef();
	useEffect(() => {
        let newItem = JSON.parse(localStorage.getItem('newsItem'));
        axiosInstance.get(`/news/detail/${newItem.id}`)
        .then(res => {
			if (res.data.success) {
				setNewsItem(res.data.content)
				
            }else{
                setNewsItem({})
            }
		});
	}, []);
	
	useEffect(() => {
		if(!newsItem) return;
		const dominfo = imgSrc.current;
		const reg2 = /<img.*?(?:>|\/>)/gi;
		const reg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i;
		const arr = newsItem.newsContent.match(reg2);
		if(!Array.isArray(arr)) return;
		for (let i = 0; i < arr.length; i++) {
			document.querySelectorAll('h6 img')[i].setAttribute('src',`${baseUrl}${arr[i].match(reg)[1]}`)
		}
	}, [newsItem]);
	if(!newsItem) return null;
return (
	<Layout style={{height:'100%'}}>
		<Content style={{height:'100%'}}>
			<TopMenu />
			<div className='subpage'>
				<header  className='news-header'>
					<h5>新闻中心</h5>
					<p>对标国际一流系统，参照国际API 670标准设计广泛应用于钢铁、石油、化工等行业的生产设备振动温度等监控机器学习准确识别传感器故障，减少异常停机AI模型边缘部署，自适应调整警报阀值，减少故障漏报误报</p>
				</header>
				<div className='news-item-nav'>
					<p>
						<Link to='/news'>新闻动态<RightOutlined /></Link>
						<span>新闻详情</span>
					</p>
				</div>
                <div className='news-item'>
                    <article className='article'>
                        <h1>{newsItem.title||''}</h1>
                        <h5> {newsItem.addTime||''} | 来源：{newsItem.newsSource||''} | 浏览：{newsItem.views||''}</h5>
                        <h6 ref={imgSrc}  dangerouslySetInnerHTML = {{__html:newsItem.newsContent}} ></h6>
                    </article>
                </div>
				<Footer />
			</div>
		</Content>
    </Layout>
  	);
});
export default (NewsItem);
