/**
 * @description 产品
 */
import React, { memo, useCallback,useState,useMemo} from "react";
import qiche6 from '../../img/qiche6.jpg';
import data1900 from '../../img/product/data1900.png';
import data2700 from '../../img/data2700.png';
import sensor from '../../img/product/node.png';
import qiche1 from '../../img/homeq11.jpg';
import qiche2 from '../../img/qiche2.jpg';
import qiche3 from '../../img/qiche3.jpg';
import qiche4 from '../../img/qiche4.jpg';
import qiche5 from '../../img/qiche5.jpg';
import qiche_jg from '../../img/qiche_jg.jpg';
import customer1 from '../../img/zs_tp6.jpg';
import customer2 from '../../img/zs_tp7.jpg';
import customer3 from '../../img/zs_tp8.jpg';
import customer4 from '../../img/zs_tp9.jpg';
import customer5 from '../../img/zs_tp10.jpg';

import TopMenu from '../../component/header.jsx';
import Footer from '../../component/footer.jsx';
import { Layout,Tabs} from 'antd';
import "./solution.css";
const { Content } = Layout;
const { TabPane } = Tabs;

const ChemistrySolution = memo((props) => {	

return (
	<Layout style={{height:'100%'}}>
		<Content style={{height:'100%'}}>
			<TopMenu />
			<div className='subpage'>
				<header  className='chemistry-solution-header'>
					<h5>化工行业解决方案</h5>
					<p>凯奥思工业互联网平台，包含联接管理平台、工业智能体、工业应用平台三大部分。定位于做企业增量的智能决策系统，实现业务在云上敏捷开发，边缘可信运行。赋能行业合作伙伴深耕工业核心业务流，持续释放潜在业务价值。</p>
				</header>
				<div className='section'>
					<div className='solution-section'>
						<h1 className='section-title'>案例背景</h1>
						<img src={qiche1} />
						<ul>
							<p>近年来,某500强化工企业的核心设备接连损坏,造成严重的原料浪费和重大的设备损失,并危及环境以及工厂周边安全。需要一种经济的在线监控方案,对分布在全球的核心设备进行集中在线监控,记录异常数据,分析故障原因,并对故障进行预测,以合理安排维护。</p>
							<li><strong>新装设备价格昂贵,迫切需要安装在线监控</strong></li>
							<li><strong>老旧核心设备接近生命周期尾部,传统月度巡检已不能满足安全生产要求</strong></li>
							<li><strong>现有的设备保护系统数据封闭,不能上云,无法监控生产过程</strong></li>
						</ul>
					</div>
				</div>
				<div className='interlace-section'>
					<div className='solution-second-section'>
						<h1 className='section-title'>解决方案</h1>
						<p className='solution-section-p'>项目部署超过500套,监控300kW以上的核心和辅助旋转设备,并延伸到往复式动设备。从定期、被动的维护转换为规划、主动的维护,降低了50%的维护成本和40%设备异常停机,提升了运行安全性。</p>
						<ul>
							<li>
								<img src={qiche2} />
								<p>采用边缘智能硬件,从现有TSI设备保护系统中收集数据,经处理计算后上传到企业私有云平台。</p>
							</li>
							<li>
								<img src={qiche3} />
								<p>通过现有传感器和新增传感器,利用边缘智能硬件,对压缩机、风机、电机、泵、齿轮箱、压力容器等进行在线监控。</p>
							</li>
							<li>
								<img src={qiche4} />
								<p>在厂区设立小型存储服务器,存储设备异常时的高频数据,供故障诊断使用。</p>
							</li>
							<li>
								<img src={qiche5} />
								<p>在平台上,利用设备及上下游多个设备的历史运行记录,建立机器学习模型,对设备故障进行预测,使用效果良好。</p>
							</li>
						</ul>
					</div>
				</div>
				<div className='section'>
					<div className='solution-third-section'>
						<h1 className='section-title'>方案架构</h1>
						<img src={qiche_jg} />
					</div>
				</div>
				<div className='interlace-section'>
					<div className='four-interlace-section'>
					<h1 className='section-title'>产品支撑</h1>
						<Tabs type="card" tabPosition="left">
							<TabPane tab="工业智能边缘终端3500" key="1">
								<div className='img'><img src={qiche6} /></div>
								<ul className='ul'>
									<li><h4>DATA WATCH 3500</h4></li>
									<li>产品特点:  高性能 大通道数 灵活配置</li>
									<li>产品用途:  大型旋转或往复式核心动设备</li>
									<li>典型场景:  大中型空分设备汽轮机透平机械大中型水轮机发电机组等</li>
								</ul>
							</TabPane>
							<TabPane tab="工业智能边缘终端2700" key="2">
								<div className='img'><img src={data2700} /></div>
								<ul className='ul'>
									<li><h4>DATA WATCH 2700</h4></li>
									<li>产品特点:  高性价比、紧凑型、灵活配置</li>
									<li>产品用途:  重要辅机动设备</li>
									<li>典型场景:  多轴联动机床，冷却塔风扇泵；风机；鼓风机；马达；磨煤机；离心机；混合器；搅拌机；空压机；中型往复式压缩机</li>
								</ul>
							</TabPane>
							<TabPane tab="工业智能边缘终端1900" key="3">
								<div className='img'><img src={data1900} /></div>
								<ul className='ul'>
									<li><h4>DATA WATCH 1900</h4></li>
									<li>产品特点:  高性价比、紧凑型、固定通道</li>
									<li>产品用途:  一般用途的旋转、往复动设备</li>
									<li>典型场景:  中高端机床，加工中心，泵机、小型电动机、粉碎机、小型旋转式或往复式压缩机，小型水力涡轮，离心机</li>
								</ul>
							</TabPane>
							<TabPane tab="工业智能传感器" key="4">
								<div className='img'><img src={sensor} /></div>
								<ul className='ul'>
									<li><h4>Chaos Data Node</h4></li>
									<li>产品特点:  Wifi+Zigbee无线传输、三年以上寿命</li>
									<li>产品用途:  工业现场不适宜布置传感器线缆</li>
									<li>典型场景:  钢铁和汽车制造行业生产流水线上电机减速机轴承座等</li>
								</ul>
							</TabPane>
						</Tabs>
					</div>
				</div>
				<div className='section'>
					<div className='customer-img'>
						<h1 className='section-title'>典型客户</h1>
						<img src={customer1} />
						<img src={customer2} />
						<img src={customer3} />
						<img src={customer4} />
						<img src={customer5} />
					</div>
				</div>
				<Footer />
			</div>
		</Content>
    </Layout>
  	);
});
export default (ChemistrySolution);
