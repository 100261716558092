import intl from "react-intl-universal";
import _ from "lodash/core";
import cn from '../locales/zh-CN.js';
import en from '../locales/en-US.js';

const SUPPORT_LOCALES = [{
    name: "English",
    value: "en-US"
},{
    name: "简体中文",
    value: "zh-CN"
}];

const CONFIG_LOCALES = {
    "zh-CN": cn,
    "en-US": en
}

export function updateLocale(locale){
    localStorage.setItem("locale", locale);
    window.location.reload();
};

export function initLocale() {
    let locale = localStorage.getItem("locale");
    console.log(locale,"111")
    let localeIndex = -1;
    _.each(SUPPORT_LOCALES, (loc, index) => {
        if (loc.value === locale) {
            localeIndex = index;
        }
    });
    if (localeIndex === -1) {
        localStorage.setItem("locale", "zh-CN");
        locale = "zh-CN";
    }
    console.log(locale,"222")
    intl.init({
        currentLocale: locale,
        locales: CONFIG_LOCALES
    });
}
