
import { message } from 'antd';
import axios from 'axios';
// export const baseUrl = 'http://njchaos.com:8080/';
export const baseUrl = window.location.origin+':8080/';
const axiosInstance = axios.create({
    baseURL: baseUrl,
    withCredentials: true
});
let number = 0;
axiosInstance.interceptors.response.use(res => {
    number = 0
    if (res.data.success) {
        return res
    } else {
        if (res.data.message !== '01') {
            message.error(res.data.message)
        }
        return res
    }
})
export { axiosInstance };