/**
 * @description 头部导航
 */
import React, { memo, useState, useCallback,useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import intl from 'react-intl-universal';
import { updateLocale } from "../locales/appActions";
import { UnorderedListOutlined,DownOutlined,ArrowRightOutlined} from '@ant-design/icons';
import logoOne from '../img/logo.png';
import './header.css';
const TopMenu = memo((props) => {
    const histroy = useHistory();
    const [headerShow,setHeaderShow] = useState(false);
    const [locationName,setLocationName] = useState('');
    // useEffect(() => {
    //     setLocationName(histroy.location.pathname||'')
    // }, [])
    const headerShowChange = useCallback(() => {
        setHeaderShow(!headerShow);
    },[headerShow]);
    return (
        <div className="header">
            <div className="header_left">
                <Link to="/">
                    <img src={logoOne} />
                </Link>
            </div>
            <div className={headerShow? "header_right show":"header_right disappear" } >
                <div className="nav">
                    <div  className="nav-ul">
                        {/* {localStorage.getItem("locale") === "en-US"?
                        <span className="langue-li" onClick={()=>{updateLocale('zh-CN')}}>CH</span>:
                        <span className="langue-li" onClick={()=>{updateLocale('en-US')}}>EN</span>
                        } */}
                        <span className={locationName==='/'? 'active':''}><Link to="/" >{intl.get("header.home")}</Link></span>
                        <span  className="nav-sapn-product" >
                                {intl.get("header.product")}
                                <DownOutlined />
                                <ul className="subpage-software subpage-nav">
                                    <b></b>
                                    <li>
                                        <h5>硬件产品</h5>
                                        <Link to="/product/3500">
                                            <p className={locationName==='/'? 'active':''}><ArrowRightOutlined />Data Watch 3500</p>
                                        </Link>
                                        <Link to="/product/2700">
                                            <p className={locationName==='/'? 'active':''}><ArrowRightOutlined />Data Watch 2700</p>
                                        </Link>
                                        <Link to="/product/1900">
                                            <p className={locationName==='/'? 'active':''}><ArrowRightOutlined />Data Watch 1900</p>
                                        </Link>
                                        <Link to="/product/node">
                                            <p className={locationName==='/'? 'active':''}><ArrowRightOutlined />Chaos wireless node</p>
                                        </Link>
                                        <Link to="/product/ctc">
                                            <p className={locationName==='/'? 'active':''}><ArrowRightOutlined />Chaos Data Node</p>
                                        </Link>
                                    </li>
                                    <li>
                                        <h5>软件产品</h5>
                                        <Link to="/product/edge">
                                            <p className={locationName==='/'? 'active':''}><ArrowRightOutlined />边缘计算与存储终端（Chaos Edge）</p>
                                        </Link>
                                        <Link to="/product/cloud">
                                            <p className={locationName==='/'? 'active':''}><ArrowRightOutlined />云端工业大数据平台（Chaos Cloud）</p>
                                        </Link>
                                        <Link to="/product/eam">
                                            <p className={locationName==='/'? 'active':''}><ArrowRightOutlined />设备运维管理系统（Chaos EAM）</p>
                                        </Link>
                                        <Link to="/product/stock">
                                            <p className={locationName==='/'? 'active':''}><ArrowRightOutlined />库存优化管理系统（Chaos IOS）</p>
                                        </Link>
                                    </li>
                                </ul>
                        </span>
                        <span className="nav-sapn-solution">
                            {intl.get("header.solution")}
                            <DownOutlined />
                            <ul className="subpage-solution subpage-nav">
                                <b></b>
                                <li>
                                    <Link to="/solution/electric">
                                        <p className={locationName==='/'? 'active':''}><ArrowRightOutlined />发电行业解决方案</p>
                                    </Link>
                                    <Link to="/solution/chemistry">
                                        <p className={locationName==='/'? 'active':''}><ArrowRightOutlined />化工行业解决方案</p>
                                    </Link>
                                    <Link to="/solution/steel">
                                        <p className={locationName==='/'? 'active':''}><ArrowRightOutlined />钢铁钢业解决方案</p>
                                    </Link>
                                    <Link to="/solution/car">
                                        <p className={locationName==='/'? 'active':''}><ArrowRightOutlined />汽车行业解决方案</p>
                                    </Link>
                                    <Link to="/solution/track">
                                        <p className={locationName==='/'? 'active':''}><ArrowRightOutlined />轨道交通解决方案</p>
                                    </Link>
                                </li>
                            </ul>
                        </span>
                        <span className={locationName==='/news'? 'active':''}><Link to="/news">{intl.get("header.news")}</Link></span>
                        <span className={locationName==='/about'? 'active':''}><Link to="/about">{intl.get("header.about")}</Link></span>
                        <span className={locationName==='/agent'? 'active':''}><Link to="/agent">{intl.get("header.agent")}</Link></span>
                    </div>
                </div>
            </div>	
            <div className="header_outline" onClick={headerShowChange}>
                <UnorderedListOutlined  className="outline" />
            </div>
            {/* {localStorage.getItem("locale") === "en-US"?
            <div className="header_english" onClick={()=>{updateLocale('zh-CN')}}>CH</div>:
            <div className="header_english" onClick={()=>{updateLocale('en-US')}}>EN</div>
            } */}
        </div>
    );
});
export default TopMenu;
