/**
 * @description 产品
 */
import React, { memo, useCallback,useState,useMemo} from "react";
import qiche6 from '../../img/qiche6.jpg';
import data1900 from '../../img/product/data1900.png';
import data2700 from '../../img/data2700.png';
import sensor from '../../img/product/node.png';
import qiche1 from '../../img/homeq31.jpg';
import qiche2 from '../../img/qiche2.jpg';
import qiche3 from '../../img/qiche3.jpg';
import qiche4 from '../../img/qiche4.jpg';
import qiche5 from '../../img/qiche5.jpg';
import qiche_jg from '../../img/qiche_jg.jpg';
import customer1 from '../../img/zs_tp6.jpg';
import customer2 from '../../img/zs_tp7.jpg';
import customer3 from '../../img/zs_tp8.jpg';
import customer4 from '../../img/zs_tp9.jpg';
import customer5 from '../../img/zs_tp10.jpg';

import TopMenu from '../../component/header.jsx';
import Footer from '../../component/footer.jsx';
import { Layout,Tabs} from 'antd';
import "./solution.css";
const { Content } = Layout;
const { TabPane } = Tabs;

const SteelSolution = memo((props) => {	

return (
	<Layout style={{height:'100%'}}>
		<Content style={{height:'100%'}}>
			<TopMenu />
			<div className='subpage'>
				<header  className='steel-solution-header'>
					<h5>钢铁行业解决方案</h5>
					<p>凯奥思工业互联网平台，包含联接管理平台、工业智能体、工业应用平台三大部分。定位于做企业增量的智能决策系统，实现业务在云上敏捷开发，边缘可信运行。赋能行业合作伙伴深耕工业核心业务流，持续释放潜在业务价值。</p>
				</header>
				<div className='section'>
					<div className='solution-section'>
						<h1 className='section-title'>案例背景</h1>
						<img src={qiche1} />
						<ul>
							<li><span>在钢铁企业，有大量的工艺风机、环保除尘风机、循环风机等，这些风机停机会给企业造成不同程度的损失。</span></li>
							<li><span>现场工作环境恶劣，高温高湿，油泥粉尘，对设备可靠性要求高；</span></li>
							<li><span>厂房内信号屏蔽严重，需要对设备进行连续不间断的监测；</span></li>
							<li><span>设备运行特征数据需要直接接入远程运维平台；</span></li>
							<li><span>在线监测比例低，以人工巡检为主，不便于故障分析溯源、关键设备状态管理。</span></li>
							<li><span>故障跟踪以离线测试为主，在发生故障时再进行分析，很难捕捉到故障波形，难以判断问题关键。</span></li>	
						</ul>
					</div>
				</div>
				<div className='interlace-section'>
					<div className='solution-second-section'>
						<h1 className='section-title'>解决方案</h1>
						<ul>
							<li>
								<img src={qiche2} />
								<p>采用边缘智能硬件，采集风机运行数据，并在边缘端处理后，将数据发送到运维平台，避免在厂区设置边缘服务中心器；</p>
							</li>
							<li>
								<img src={qiche3} />
								<p>通过监控手段的实施，有效降低故障停机时间50%以上；</p>
							</li>
							<li>
								<img src={qiche4} />
								<p>数据开放，平台兼容性好，无平台接入费用；</p>
							</li>
							<li>
								<img src={qiche5} />
								<p>根据风机的运转数据，在边缘端提取机理特征数据，结合平台端的大数据分析方法，对历史数据进行分析，实现设备故障的智能诊断；</p>
							</li>
							<li>
								<img src={qiche3} />
								<p>旋转设备故障中90%以上可以做到自动预警，避免异常停机事故的发生。</p>
							</li>
							<li>
								<img src={qiche4} />
								<p>通过在线振动、油液等监测手段的应用，不对中、不平衡、松动、轴承磨损、齿轮磨损等电机以及机械类故障均可以通过模型自动预警。</p>
							</li>
						</ul>
					</div>
				</div>
				<div className='section'>
					<div className='solution-third-section'>
						<h1 className='section-title'>方案架构</h1>
						<img src={qiche_jg} />
					</div>
				</div>
				<div className='interlace-section'>
					<div className='four-interlace-section'>
					<h1 className='section-title'>产品支撑</h1>
						<Tabs type="card" tabPosition="left">
							<TabPane tab="工业智能边缘终端3500" key="1">
								<div className='img'><img src={qiche6} /></div>
								<ul className='ul'>
									<li><h4>DATA WATCH 3500</h4></li>
									<li>产品特点:  高性能 大通道数 灵活配置</li>
									<li>产品用途:  大型旋转或往复式核心动设备</li>
									<li>典型场景:  大中型空分设备汽轮机透平机械大中型水轮机发电机组等</li>
									{/* <li><a>了解更多》</a></li> */}
								</ul>
							</TabPane>
							<TabPane tab="工业智能边缘终端2700" key="2">
								<div className='img'><img src={data2700} /></div>
								<ul className='ul'>
									<li><h4>DATA WATCH 2700</h4></li>
									<li>产品特点:  高性价比、紧凑型、灵活配置</li>
									<li>产品用途:  重要辅机动设备</li>
									<li>典型场景:  多轴联动机床，冷却塔风扇泵；风机；鼓风机；马达；磨煤机；离心机；混合器；搅拌机；空压机；中型往复式压缩机</li>
								</ul>
							</TabPane>
							<TabPane tab="工业智能边缘终端1900" key="3">
								<div className='img'><img src={data1900} /></div>
								<ul className='ul'>
									<li><h4>DATA WATCH 1900</h4></li>
									<li>产品特点:  高性价比、紧凑型、固定通道</li>
									<li>产品用途:  一般用途的旋转、往复动设备</li>
									<li>典型场景:  中高端机床，加工中心，泵机、小型电动机、粉碎机、小型旋转式或往复式压缩机，小型水力涡轮，离心机</li>
								</ul>
							</TabPane>
							<TabPane tab="工业智能传感器" key="4">
								<div className='img'><img src={sensor} /></div>
								<ul className='ul'>
									<li><h4>Chaos Data Node</h4></li>
									<li>产品特点:  Wifi+Zigbee无线传输、三年以上寿命</li>
									<li>产品用途:  工业现场不适宜布置传感器线缆</li>
									<li>典型场景:  钢铁和汽车制造行业生产流水线上电机减速机轴承座等</li>
								</ul>
							</TabPane>
						</Tabs>
					</div>
				</div>
				<div className='section'>
					<div className='customer-img'>
						<h1 className='section-title'>典型客户</h1>
						<img src={customer1} />
						<img src={customer2} />
						<img src={customer3} />
						<img src={customer4} />
						<img src={customer5} />
					</div>
				</div>
				<Footer />
			</div>
		</Content>
    </Layout>
  	);
});
export default (SteelSolution);
