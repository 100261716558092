/**
 * @description 产品2300
 */
import React, { memo, useCallback,useState,useMemo} from "react";
import { Link } from "react-router-dom";
import icon1 from '../../../img/product/icon2300one.png';
import icon2 from '../../../img/product/icon2300two.png';
import icon3 from '../../../img/product/icon2300third.png';
import TopMenu from '../../../component/header.jsx';
import Footer from '../../../component/footer.jsx';
import Customer from '../../../component/customer.jsx';
import "./hardware.css";
import { Layout } from 'antd';
const { Content } = Layout;

const Hardware3500 = memo((props) => {

return (
	<Layout style={{height:'100%'}}>
		<Content style={{height:'100%'}}>
			<TopMenu />
			<div className='subpage'>
				<header  className='hardware2300-header'>
					<h5>工业智能硬件产品</h5>
					<p>对标国际一流系统，参照国际API 670标准设计广泛应用于钢铁、石油、化工等行业的生产设备振动温度等监控机器学习准确识别传感器故障，减少异常停机AI模型边缘部署，自适应调整警报阀值，减少故障漏报误报。</p>
				</header>
				<div className='hardware2300-section'>
					<div  className='hardware2300-second'>
						<div  className='hardware3500-second-left'></div>
						<div  className='hardware2300-second-right'>
							<h2>Data Watch 3500  </h2>
							<h5>凯奥思工业边缘智能终端</h5>
							<p>凯奥思工业边缘智能硬件Data Watch 3500 是新一代的设备监控和边缘计算终端，可兼容各种工业传感器，采集设备运行的振动位移、速度、加速度、噪声、温度、压力、液位、阀门位置等不同类型的信号，在设备边缘进行信号处理、特征提取和智能识别，将计算后的静态特征数据和动态原始数据传输到企业云平台或数据中心，用于设备故障诊断和预测。</p>
							<p> 对于已经安装振动监测与保护系统，如本特利3500、Vibrometer 600、CSI 6500等的机械设备，采用Data Watch 3500 接入这些保护系统，可以采集原始数据，并进行特征提取，将数据上传至云端。当保护系统发出警报时或者关停设备时，记录详细警报信息，对数据进行分析，提供发生故障的原因。避免了采购昂贵的专业数据分析软件。</p>
						</div>
					</div>
					<div  className='hardware2300-third'>
						<ul>
							<li>
								<img src={icon1}/>
								<div>
									<h5>高性能</h5>
									<p>系统采用5.7 英寸触摸显示屏和Linux 操作系统，拥有2GB内存和64GB存储空间</p>
								</div>
							</li>
							<li>
								<img src={icon2}/>
								<div>
									<h5>大通道数</h5>
									<p>拥有强大的并行计算力，可同时处理多达60个振动通道的同步采样、信号处理、数据记录和传输</p>
								</div>
							</li>
							<li>
								<img src={icon3}/>
								<div>
									<h5>灵活配置</h5>
									<p>运行速度快、工作可靠，采用图形用户界面，操作简单、方便</p>
								</div>
							</li>
						</ul>
					</div>
				</div>
				<div className='hardware2300-four'>
					<h1>产品参数</h1>
					<ul>
						<li><strong>产品型号</strong>Data Watch 3500</li>
						<li><strong>产品用途</strong>大型旋转或往复式核心动设备</li>
						<li><strong>技术参数</strong>ARM Cortex-A9 双核处理器2GB内存，64GB存储</li>
						<li><strong>FPGA</strong>Zynq 7030</li>
						<li><strong>通道数</strong>6~60可配置</li>
						<li><strong>网络通讯</strong>Ethernet WIFI Bluetooth</li>
						<li><strong>安装方式</strong>工控室 机架安装</li>
					</ul>
				</div>
				<Customer />
				<Footer />
			</div>
		</Content>
    </Layout>
  	);
});
export default (Hardware3500);
