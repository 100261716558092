/**
 * @description 头部导航
 */
import React, { memo, useState, useMemo,useEffect } from "react";
import { Link } from "react-router-dom";
import qrCode from '../img/qrCode.jpg';
import './footer.css';

const Footer = memo((props) => {
return (
    <footer className="all-footer">
        <div className="all">
            <div className="left">
                <h5>联系方式</h5>
                <p>地址：南京市雨花台区宁双路19号软件谷云密城J栋7楼</p>
                <p>电话：025-83132381</p>
                <p>邮箱：contact@njchaos.com</p>
            </div>
            <div className="right">
                <p>关注公众号</p>
                <img src={qrCode} />
            </div>
            <div className="bottom">
                <p>Copyright 2017-2020 南京凯奥思数据技术有限公司. 版权所有</p>
                <p>苏ICP备18005519号</p>
            </div>
        </div>
    </footer>
);});
export default Footer;
