/**
 * @description 产品
 */
import React, { memo, useCallback,useState,useEffect} from "react";
import { Link } from "react-router-dom";
import news1 from '../../img/news1.jpg';
import { axiosInstance,baseUrl} from "../../utils/axiosInstance";
import TopMenu from '../../component/header.jsx';
import Footer from '../../component/footer.jsx';
import { Layout,Tabs} from 'antd';
import "./news.css";
const { Content } = Layout;
const { TabPane } = Tabs;

const News = memo((props) => {
	const [newList, setNewList] = useState([]);
	const [index, setIndex] = useState(1);

	useEffect(() => {
		axiosInstance.get(`/news/overView/${index}`)
		.then(res => {
			if (res.data.success) {
				setNewList(res.data.content)
			}else{
				setNewList([])
			}
		});
	}, [index]);

	const callback = useCallback((val) => {
		setIndex(val)
	},[])	

return (
	<Layout style={{height:'100%'}}>
		<Content style={{height:'100%'}}>
			<TopMenu />
			<div className='subpage'>
				<header  className='news-header'>
					<h5>新闻中心</h5>
					<p>对标国际一流系统，参照国际API 670标准设计广泛应用于钢铁、石油、化工等行业的生产设备振动温度等监控机器学习准确识别传感器故障，减少异常停机AI模型边缘部署，自适应调整警报阀值，减少故障漏报误报</p>
				</header>
				<div className='section'>
					<div className='news'>
						<Tabs defaultActiveKey="1" onChange={callback}>
							<TabPane tab="公司新闻" key="1">
								{newList.length>0?
								newList.map((item,index)=>{
									return	<Link to='newsItem' key={item.id} >
									<div 
									className='news-container' 
									onClick={()=>{
										localStorage.setItem('newsItem',JSON.stringify(item))
									}}
									>
										<div><img src={baseUrl+item.picturePath}/></div>
										<div>
											<h5>{item.title}</h5>
											<p>{item.newsOverView}</p>
											<span>{item.addTime}</span>
										</div>
									</div></Link>
								}):<div></div>}
							</TabPane>
							<TabPane tab="行业新闻" key="2">
								{newList.length>0?
								newList.map((item,index)=>{
									return	<Link to='newsItem' key={item.id} >
									<div 
									className='news-container' 
									onClick={()=>{
										localStorage.setItem('newsItem',JSON.stringify(item))
									}}
									>
										<div><img src={baseUrl+item.picturePath}/></div>
										<div>
											<h5>{item.title}</h5>
											<p>{item.newsOverView}</p>
											<span>{item.addTime}</span>
										</div>
									</div></Link>
								}):<div></div>}
							</TabPane>
						</Tabs>
					</div>
				</div>
				<Footer />
			</div>
		</Content>
    </Layout>
  	);
});
export default (News);
