/**
 * @description 无线传感器
 */
import React, { memo, useCallback,useState,useEffect} from "react";
import { axiosInstance,baseUrl} from "../../utils/axiosInstance";
import { Link } from "react-router-dom";
import phone from '../../img/phone.png';
import email2 from '../../img/email2.png';
import shoping from '../../img/shoping.png';
import phone2 from '../../img/phone2.png';
import down from '../../img/down.png';
import itemLeft from '../../img/zhutu.jpg';
import TopMenu from '../../component/header.jsx';
import Footer from '../../component/footer.jsx';
import { Layout,Tabs,Collapse,Popover} from 'antd';
import "./product.css";
const { Content } = Layout;
const { TabPane } = Tabs;
const { Panel } = Collapse;
const NodeItem = memo((props) => {
const [defaultData, setDefaultData] = useState(0);
const [index, setIndex] = useState(5);
const [nodeData, setNodeData] = useState({});
const [chooseList, setChooseList] = useState([]);
const [chooseChildList, setChooseChildList] = useState([]);

useEffect(() => {
    let nodeItem = JSON.parse(localStorage.getItem('nodeItem'));
    setNodeData(nodeItem||{})
}, [defaultData]);

useEffect(() => {
    axiosInstance.get(`/product/allType`)
    .then(res => {
        if (res.data.success) {
            setChooseList(res.data.content)
        }else{
            setChooseList([])
        }
    });
}, []);

const callback = useCallback((val) => {
    console.log(val)
    if(val){
        axiosInstance.get(`/product/name/${val}`)
        .then(res => {
            if (res.data.success) {
                setChooseChildList(res.data.content||[])
            }else{
                setChooseChildList([])
            }
        });
    }
},[])	

const nameChange = useCallback((id) => {
    axiosInstance.get(`/product/detail/${id}`)
    .then(res => {
        if (res.data.success) {
            setNodeData(res.data.content||{})
        }else{
            setNodeData({})
        }
    });
},[])	

const dataDownload = useCallback((path) => {
    window.location.href = `${baseUrl}product/download?materialPath=${path}`
    // axiosInstance.get(`product/download?materialPath=${path}`)
    // .then(res => {
    //     if (res.data.success) {
    //         setNodeData(res.data.content||{})
    //     }else{
    //         setNodeData({})
    //     }
    // });
},[])

return (
	<Layout style={{height:'100%'}}>
		<Content style={{height:'100%'}}>
			<TopMenu />
			<div className='subpage'>
				<header  className='product-header'>
					<h5>无线传感器</h5>
					<p>无线是一家致力于提供高品质设备振动监测与保护产品的高科技公司，其产品涵盖加速度传感器、速度传感器、电涡流振动探头、线缆、连接器、接线箱、信号调理器、振动仪表/继电器以及相关安装测试配件等。</p>
				</header>
				<div className='nodeItem'>
					<div className='left'>
						<h5 className='title'>产品中心</h5>
                        <Collapse onChange={callback}
                        ghost
                        accordion
                        expandIconPosition="right"
                        >
                            {chooseList.length>0?   chooseList.map((item,index)=>{
                                return <Panel  
                                className='bg' 
                                header={item} 
                                key={item}
                                >
                                    {chooseChildList.length>0?   
                                    chooseChildList.map((item,index)=>{
                                        return  <p 
                                        key={item.id}
                                        onClick={()=>{nameChange(item.id)}}
                                        >{item.name}</p>
                                    }):null}
                                </Panel>
                            }):null}
                            
                        </Collapse>
						<h5 className='title node-contact'>联系方式</h5>
						<div className='bg1'>
                            <p><img src={phone} />132-8555-9620（销售）</p>
                            <p><img src={phone} className='hidden' />178-9502-6465（技术）</p>
							<p><img src={email2} />tao.fangmei@njchaos.com </p>
                            <p><img src={email2}  className='hidden' />tao.zhaohua@njchaos.com</p>
						</div>
					</div>
					<div className='right'>
                        <div className="top">
                            <div className="left">
                                <img src={nodeData.imgPath? baseUrl+nodeData.imgPath:''} />
                            </div>
                            <div className="right">
                                <h5>{nodeData.name||''}</h5>
                                <p>品牌：{nodeData.brand||''} </p>
                                <p>描述：{nodeData.description||''}</p>
                                <p className="button">
                                    <button className="bug"><img src={shoping} />立即购买</button>
                                    <Popover 
                                    content={
                                        <div  style={{padding:'0 2px'}} >
                                            <p><img src={phone}   style={{marginRight:'5px'}} />132-8555-9620（销售）</p>
                                            <p><img src={phone}  style={{marginRight:'5px',visibility:'hidden'}}  />178-9502-6465（技术）</p>
                                            <p><img src={email2}  style={{marginRight:'5px'}} />tao.fangmei@njchaos.com </p>
                                            <p><img src={email2}  style={{marginRight:'5px',visibility:'hidden'}} />tao.zhaohua@njchaos.com</p>
                                        </div> 
                                    } 
                                    title="联系方式" 
                                    trigger="hover"
                                    >
                                        <button className="consult"><img src={phone2} />咨询报价</button>
                                    </Popover>
                                    <button className="consult" onClick={()=>{dataDownload(nodeData.materialPath)}}>
                                        <img src={down} />资料下载
                                    </button>
                                </p>
                            </div>
                        </div>
                        <Tabs type="card">
                            <TabPane tab="规格参数" key="1">
                                <ul className="bottom">
                                    <li>
                                        <h5>规格项</h5>
                                        <h5>参数值</h5>
                                    </li>
                                    <li>
                                        <span>重量</span>
                                        <span>{nodeData.weight||''}</span>
                                    </li>
                                    <li>
                                        <span>灵敏度</span>
                                        <span>{nodeData.sensitivity||''}</span>
                                    </li>
                                    <li>
                                        <span>频率响应（±3dB）</span>
                                        <span>{nodeData.frequencyResp||''}</span>
                                    </li>
                                    <li>
                                        <span>动态范围</span>
                                        <span>{nodeData.dynamicRange||''}</span>
                                    </li>
                                    <li>
                                        <span>供电电压</span>
                                        <span>{nodeData.supplyVoltage||''}</span>
                                    </li>
                                    <li>
                                        <span>温度范围</span>
                                        <span>{nodeData.tempRange||''}</span>
                                    </li>
                                    <li>
                                        <span>外壳材料</span>
                                        <span>{nodeData.shellMaterial||''}</span>
                                    </li>
                                    <li>
                                        <span>连接方式</span>
                                        <span>{nodeData.conMethod||''}</span>
                                    </li>
                                    <li>
                                        <span>最高温度</span>
                                        <span>{nodeData.maxTemp||''}</span>
                                    </li>
                                    <li>
                                        <span>谐振频率</span>
                                        <span>{nodeData.resonantFreq||''}</span>
                                    </li>
                                </ul>
                            </TabPane>
                            <TabPane tab="结构说明" key="2">
                                <div className="TabPane">
                                    <img src={nodeData.structurePath? baseUrl+nodeData.structurePath:''} />
                                </div>
                            </TabPane>
                        </Tabs>
                    </div>
				</div>
                <div className='bg2'>
                    <h5 className='title'>联系方式</h5>
                    <div className='bg1'>
                        <p><img src={phone} />132-8555-9620（销售）</p>
                            <p><img src={phone} className='hidden' />178-9502-6465（技术）</p>
                        <p><img src={email2} />tao.fangmei@njchaos.com </p>
                        <p><img src={email2}  className='hidden' />tao.zhaohua@njchaos.com</p>
                    </div>
                </div>	
				<Footer />
			</div>
		</Content>
    </Layout>
  	);
});
export default (NodeItem);
