/**
 * @description 产品
 */
import React, { memo, useCallback,useState,useMemo} from "react";
import { Link } from "react-router-dom";
import companyTitle from '../../img/company.png';
import enterpriseTitle from '../../img/enterprise.png';
import growingeTitle from '../../img/growing.png';
import growingBtTitle from '../../img/growing_bt.png';
import joinBtTitle from '../../img/join_bt.png';
import contactusTitle from '../../img/contactus_bt.png';
import enterprise1 from '../../img/enterprise_ico1.png';
import enterprise2 from '../../img/enterprise_ico2.png';
import enterprise3 from '../../img/enterprise_ico3.png';
import company1 from '../../img/company_1.png';
import certificate1 from '../../img/honor/1.png';
import certificate2 from '../../img/honor/2.png';
import certificate3 from '../../img/honor/3.png';
import certificate4 from '../../img/honor/5.png';
import certificate5 from '../../img/honor/ryzz_1.png';
import certificate6 from '../../img/honor/7.png';
import certificate7 from '../../img/honor/ryzz_2.png';
import certificate8 from '../../img/honor/ryzz_3.png';
import certificate9 from '../../img/honor/ryzz_4.png';
import certificate10 from '../../img/honor/ryzz_5.png';
import phone from '../../img/phone.png';
import adress from '../../img/adress2.png';
import email from '../../img/email2.png';
import qrCode from '../../img/qrCode.jpg';
import homeMap from '../../img/home_map.jpg';
import TopMenu from '../../component/header.jsx';
import Footer from '../../component/footer.jsx';
import { Layout,Timeline,Carousel,Collapse } from 'antd';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import "./about.css";
const { Content } = Layout;
const { Panel } = Collapse;

const About = memo((props) => {
const [index, setIndex] = useState(5);
const [indexMemorabilia, setIndexMemorabilia] = useState(1);

const callback = useCallback((val) => {
	setIndex(val)
},[])

const carouselChange  = useCallback((a, b, c) => {
	console.log(a, b, c);
},[])

const changeMemorabilia  = useCallback((type) => {
	if(type==='left'){
		let newIndex = indexMemorabilia===1? 4:indexMemorabilia-1;
		setIndexMemorabilia(newIndex)
	}else{
		let newIndex = indexMemorabilia===4? 1:indexMemorabilia+1;
		setIndexMemorabilia(newIndex)
	}
},[indexMemorabilia])

const homeMain = useMemo(() => {
    switch (indexMemorabilia) {
		case 1:
			return <Timeline mode="alternate" >
				<Timeline.Item><strong >8月</strong>凯奥思公司参加了江苏省工信厅举办的第五届i创杯专项资金授予仪式</Timeline.Item>
				<Timeline.Item><strong className='strong-right'>3月</strong>与国际能源部资助的螺旋对称等离子体聚变实验室签署合作协议</Timeline.Item>
				<Timeline.Item><strong>2月</strong>总经理入选南京市创新型企业家培育计划。</Timeline.Item>
			</Timeline>
			break;
		case 2:
			return <Timeline mode="alternate" >
				<Timeline.Item><strong className='strong-right'>11月</strong>凯奥思公司获得国家高新技术企业。</Timeline.Item>
				<Timeline.Item><strong>9月</strong>凯奥思公司获得江苏省民营科技企业。</Timeline.Item>
				<Timeline.Item><strong className='strong-right'>9月</strong>凯奥思公司获得江苏省第五届i创杯创新创业大赛二等奖。</Timeline.Item>
				<Timeline.Item><strong>5月</strong>凯奥思产品Data Watch 获得省工信厅授予的“江苏省优秀大数据产品”称号。</Timeline.Item>
				<Timeline.Item><strong className='strong-right'>5月</strong>凯奥思公司获得3A信用企业认证。</Timeline.Item>
				<Timeline.Item><strong>4月</strong>凯奥思公司获得ISO9001质量体系认证。</Timeline.Item>
				<Timeline.Item><strong className='strong-right'>3月</strong>凯奥思公司获得江苏省软件企业。</Timeline.Item>
				<Timeline.Item><strong>2月</strong>总经理入选南京市高层次创业人才计划。</Timeline.Item>
				<Timeline.Item><strong className='strong-right'>1月</strong>凯奥思公司获得2018中国（南京）软件互联网创新创业大赛亚军。</Timeline.Item>
			</Timeline>
			break;
		case 3:
			return  <Timeline mode="alternate" >
				<Timeline.Item><strong className='strong-right'>11月</strong>凯奥思公司江苏省中小型科技企业。</Timeline.Item>
				</Timeline>
			break;
		case 4:
			return <Timeline mode="alternate" >
					<Timeline.Item><strong className='strong-right'>7月</strong>总经理入选南京市雨花台区高层次创业人才计划。</Timeline.Item>
				</Timeline>
			break;
		default:
			break;
	}
}, [indexMemorabilia]);

return (
	<Layout style={{height:'100%'}}>
		<Content style={{height:'100%'}}>
			<TopMenu />
			<div className='subpage'>
				<header  className='about-header'>
					<h5>关于我们</h5>
					<p>聚焦智能制造，专注于动设备的智慧运维为工业领域客户提供更加智能、安全、高效的工业互联网产品及解决方案。</p>
				</header>
				<div className='section'>
					<div className='company about'>
						<div className='about-title'><img src={companyTitle} /></div>
						<div className='left'>
							<p><strong>南京凯奥思数据技术</strong>(Chaos Data Technology)聚集工业物联网，以工业企业动设备（旋转、往复）在线过程监控保护、智能故障诊断和智慧运维为切入点，结合边缘和云端的机理与大数据建模分析，合理安排设备维护、降低设备停机风险和安全隐患，为工业领域客户提供更加智能、安全、高效的工业互联网产品及解决方案。</p>
							<p>凯奥思数据核心技术团队均在世界500强企业有多年工作经历，项目入选南京市海外高层次人才引进项目。公司技术和产品，在钢铁、化工、汽车制造等多个工业领域获得广泛应用。</p>
							<p>凯奥思数据得到本地政府的大力扶持，是中小型科技企业、双软企业和国家高新技术企业。公司拥有雄厚研发实力，与东南大学、南京信息工程大学、南理工等国内知名高等院校建立长期产学研合作关系。</p>
						</div>
						<div className='right'>
							<img  src={company1} />
						</div>
					</div>
				</div>
				<div className='about-second-section'>
					<div className='enterprise about'>
						<div className='about-title'><img src={enterpriseTitle} /></div>
						<ul>
							<li>
								<img src={enterprise1} />
								<p>企业愿景</p>
								<span>凯奥思数据、让工业更智能</span>
							</li>
							<li>
								<img src={enterprise2} />
								<p>核心价值观</p>
								<span>行为标准及行为解读</span>
							</li>
							<li>
								<img src={enterprise3} />
								<p>我们的使命</p>
								<span>为客户提供有竞争力的工业智能化解决方案和服务，持续为客户创造最大价值。</span>
							</li>
						</ul>
					</div>
				</div>
				<div className='section'>
					<div className='growing about'>
						<div className='about-title'><img src={growingeTitle} /></div>
						<ul className='top'>
							<li onClick={()=>{changeMemorabilia('left')}}><LeftCircleOutlined /></li>
							<li onClick={()=>{setIndexMemorabilia(1)}} className={indexMemorabilia===1?'active':''}>2020年<span></span></li>
							<li  onClick={()=>{setIndexMemorabilia(2)}} className={indexMemorabilia===2?'active':''}>2019年<span></span></li>
							<li onClick={()=>{setIndexMemorabilia(3)}} className={indexMemorabilia===3?'active':''}>2018年<span></span></li>
							<li onClick={()=>{setIndexMemorabilia(4)}} className={indexMemorabilia===4?'active':''}>2017年<span></span></li>
							<li onClick={()=>{changeMemorabilia('right')}}><RightCircleOutlined /><span></span></li>
						</ul>
						<div className='alternate'>{homeMain}</div>
					</div>
				</div>
				<div className='interlace-section'>
					<div className='about certificate'>
						<div className='about-title'><img src={growingBtTitle} /></div>
						<Carousel afterChange={carouselChange}>
							<div className='carousel'>
								<div><img src={certificate1} /></div>
								<div><img src={certificate2} /></div>
								<div><img src={certificate3} /></div>
								<div><img src={certificate4} /></div>
								<div><img src={certificate5} /></div>
							</div>
							<div className='carousel'>
								<div><img src={certificate6} /></div>
								<div><img src={certificate7} /></div>
								<div><img src={certificate8} /></div>
								<div><img src={certificate9} /></div>
								<div><img src={certificate10} /></div>
							</div>
						</Carousel>
					</div>
				</div>
				<div className='section'>
					<div className='join about'>
						<div className='about-title'><img src={joinBtTitle} /></div>
						<ul>
							<li  className='about-ul-first'>
								<span>职位名称</span>
								<span>领域方向</span>
								<span>工作地点</span>
								<span>发布时间</span>
							</li>
							<Collapse  ghost>
								<Panel header={
									<li>
									<span>Java全栈工程师</span>
									<span>通信、电子、计算机等相关专业</span>
									<span>南京市雨花台区宁双路19号</span>
									<span>2020-11-13</span>
								</li>
								} 
								key="1"  
								showArrow={false}
								>
									<p>技能要求：</p>
									<p>1. 全栈偏后台，精通Java和python，熟悉js，jQuery，css等前端技术，有良好的编程习惯；</p>
									<p>2. 具备扎实的Java基础知识，以及以Java为主的全栈工作经验；</p>
									<p>3. 精通mySQL等关系型数据和常用NoSQ技术，如Redis\MongoDB；，有Hadoop应用经验的优先；</p>
									<p>4. 熟练掌握常用的设计模式，熟悉Spring、MyBatis、Struts、ibatis、SpringBoot、Springcloud、Dubbo、java微服务等开源技术架构及原理；</p>
									<p>5. 有一定的架构设计能力，对高并发、高稳定大数据处理有过实际项目及产品经验优先；</p>
									<p>6. 具有很强的分析复杂问题和解决复杂问题的能力，有强烈的责任心。</p>
								</Panel>
								<Panel 
								showArrow={false}
								header={
									<li>
									<span>嵌入式硬件工程师</span>
									<span>研发项目元器件</span>
									<span>南京市雨花台区宁双路19号</span>
									<span>2020-11-13</span>
								</li>
								} 
								key="2" >
									<p>技能要求：</p>
									<p>1、负责研发项目元器件选型，原理图设计，PCB板设计，BOM的生成及维护；</p>
									<p>2、负责PCB板调试过程中出现的问题的解决及整改方案, 参与项目安全风险分析中跟硬件有关部分的分析及验证；</p>
									<p>3、参与产品功能、性能设计和系统总体结构设计, 参与投产试制；</p>
									<p>4、产品EMC摸底测试及问题解决；</p>
									<p>5、完成研发项目经理下达的新产品硬件开发计划，根据总体技术方案，实施产品电路设计及样机试制，并满足相关标准要求。</p>
								</Panel>
								<Panel 
								showArrow={false}
								header={
									<li>
									<span>前端开发工程师</span>
									<span>通信、电子、计算机等相关专业</span>
									<span>南京市雨花台区宁双路19号</span>
									<span>2020-11-13</span>
								</li>
								} 
								key="3" >
									<p>技能要求：</p>
									<p>1. 精通JavaScript、Ajax，有2年及以上前端开发经验；</p>
									<p>2. 对React.js，VUE.js，Angular.js等MVVM框架能熟练运用至少一种，React框架优先，且了解其基本原理；</p>
									<p>3. 熟悉HTML、DIV CSS等前端技术，对浏览器兼容性、前端性能优化等有深入理解；</p>
									<p>4. 具有一定的审美能力，对排版、配色、字体设计等有正确和谐的理解，理解用户体验、交互操作流程设计、及用户需求；</p>
									<p>5. 为人诚实正直，做事认真负责，具备良好的逻辑思维能力和沟通协作能力；</p>
									<p>6. 善于沟通，有良好的团队合作精神。</p>
								</Panel>
								<Panel 
								showArrow={false}
								header={
									<li>
									<span>嵌入式linux软件工程师</span>
									<span>通信、电子、计算机等相关专业</span>
									<span>南京市雨花台区宁双路19号</span>
									<span>2020-11-13</span>
								</li>
								} 
								key="4" >
									<p>技能要求：</p>
									<p>1. 通信、电子、计算机等相关专业本科及以上学历；</p>
									<p>2. 精通C/C++语言，有2-3年linux下的应用开发相关开发经验，熟练掌握TCP/IP通信协议及编程，有mqtt、opc-ua、modbus tcp开发经验； </p>
									<p>3. 有Linux驱动、stm32单片机开发工作经验，熟悉Keil等开发环境，熟悉MCU相关的外设ADC、SPI、UART、i2C等的应用和MCU的各种休眠、唤醒模式和能耗管理；</p>
									<p>4. 熟悉跨平台QT开发（windows/linux）、QTcreator、GUI界面，有终端QT移植经验；</p>
									<p>5. 具有良好的数字和模拟电路基础，有信号处理背景优先；</p>
									<p>6. 具有良好的英语读写能力；</p>
									<p>7. 熟悉敏捷开发，有责任心，学习能力强，有良好的团队写作沟通能力</p>
								</Panel>
							</Collapse>
						</ul>
					</div>
				</div>
				<div className='interlace-section' style={{padding:'0'}}>
					<div className='about'>
						<div className='about-title'><img src={contactusTitle} /></div>
						<div className="contact-us">
							<img className="component-map" src={homeMap} />
							<div className="left">
								<h5>南京凯奥思技术股份有限公司</h5>
								<p><img src={phone} /><span>地址：南京市雨花台区宁双路19号软件谷云密城J栋7楼</span></p>
								<p><img src={adress} /><span>025-83132381</span></p>
								<p><img src={email} /><span>contact@njchaos.com</span></p>
							</div>
							<div className="right">
								<img src={qrCode} />
								<p>关注凯奥思公众号</p>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		</Content>
    </Layout>
  	);
});
export default (About);
