import React from "react";
import { HashRouter as Router, Route,Switch } from 'react-router-dom';
import { initLocale } from "./locales/appActions";
import Home from "./page/home/home";
import Product from "./page/product/product";
import NodeItem from "./page/product/nodeItem";
import CarSolution from "./page/solution/car";
import ChemistrySolution from "./page/solution/chemistry";
import ElectricSolution from "./page/solution/electric";
import SteelSolution from "./page/solution/steel";
import TrackSolution from "./page/solution/track";
import Cloud from "./page/product/software/cloud";
import Edge from "./page/product/software/edge";
import Eam from "./page/product/software/eam";
import Stock from "./page/product/software/stock";
import CtcItem from "./page/product/software/ctcItem";
import Hardware2700 from "./page/product/hardware/hardware2700";
import Hardware1900 from "./page/product/hardware/hardware1900";
import Hardware3500 from "./page/product/hardware/hardware3500";
import News from "./page/news/news";
import NewsItem from "./page/news/newsItem";
import About from "./page/about/about";
import Agent from "./page/agent/agent";
import ErrorPage from './page/error/404';
function App() {
  initLocale();
  return (
      <Router>
        <Switch>
          <Route  path="/news"   component={News} />
          <Route  path="/newsItem"   component={NewsItem} />
          <Route  path="/about"   component={About} />
          <Route  path="/agent"   component={Agent} />
          <Route path="/404" component={ErrorPage} />
          <Route  path="/solution/car"   component={CarSolution} />
          <Route  path="/solution/chemistry"   component={ChemistrySolution} />
          <Route  path="/solution/electric"   component={ElectricSolution} />
          <Route  path="/solution/steel"   component={SteelSolution} />
          <Route  path="/solution/track"   component={TrackSolution} />
          <Route  path="/product/cloud"   component={Cloud} />
          <Route  path="/product/edge"   component={Edge} />
          <Route  path="/product/eam"   component={Eam} />
          <Route  path="/product/stock"   component={Stock} />
          <Route  path="/product/ctc"   component={CtcItem} />
          <Route  path="/product/2700"   component={Hardware2700} />
          <Route  path="/product/1900"   component={Hardware1900} />
          <Route  path="/product/3500"   component={Hardware3500} />
          <Route  path="/product/node"   component={Product} />
          <Route  path="/node/item"   component={NodeItem} />
          <Route  path="/"  component={Home} />
        </Switch>
      </Router>
  );
}

export default App;