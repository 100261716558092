/**
 * @description 软件产品Cloud
 */
import React, { memo, useCallback,useState,useMemo} from "react";
import { Link } from "react-router-dom";
import second1 from '../../../img/ruanj2.jpg';
import second2 from '../../../img/ruanj3.jpg';
import second3 from '../../../img/ruanj4.jpg';
import second4 from '../../../img/ruanj5.jpg';
import customer1 from '../../../img/zs_tp6.jpg';
import customer2 from '../../../img/zs_tp7.jpg';
import customer3 from '../../../img/zs_tp8.jpg';
import customer4 from '../../../img/zs_tp9.jpg';
import customer5 from '../../../img/zs_tp10.jpg';
import TopMenu from '../../../component/header.jsx';
import Footer from '../../../component/footer.jsx';
import { Layout,Tabs} from 'antd';
import "./software.css";
const { Content } = Layout;
const { TabPane } = Tabs;

const Cloud = memo((props) => {

return (
	<Layout style={{height:'100%'}}>
		<Content style={{height:'100%'}}>
			<TopMenu />
			<div className='subpage'>
				<header  className='software-header'>
					<h5>工业智能软件产品</h5>
					<p>对标国际一流系统，参照国际API 670标准设计广泛应用于钢铁、石油、化工等行业的生产设备振动温度等监控机器学习准确识别传感器故障，减少异常停机AI模型边缘部署，自适应调整警报阀值，减少故障漏报误报</p>
				</header>
				<div className='one-section'>
					<div className='software-section'>
						<ul className='top'>
							<li className='cloud'></li>
							<li>
								<h1>云端工业大数据平台 Chaos Cloud</h1>
								<p>集成Tensorflow、Pytorch，Keras 深度学习框架</p>
								<p>20多个先进机器学习算法，自动选择最优预测模型</p>
								<p>50多种风机、泵相关故障机理模型，实现故障智能诊断</p>
							</li>
						</ul>
						<ul className='cloud-bottom'>
							<li>
								<img src={second1} />
								<p>集成Tensorflow、Pytorch，Keras 深度学习框架</p> 
							</li>
							<li>
								<img src={second2} />
								<p>20多个先进机器学习算法，自动选择最优预测模型</p> 
							</li>
							<li>
								<img src={second3} />
								<p>50多种风机、泵相关故障机理模型，实现故障智能诊断</p> 
							</li>
						</ul>
					</div>
				</div>
				<div className='software-customer-img'>
					<h1 className='section-title'>典型客户</h1>
					<img src={customer1} />
					<img src={customer2} />
					<img src={customer3} />
					<img src={customer4} />
					<img src={customer5} />
				</div>
				<Footer />
			</div>
		</Content>
    </Layout>
  	);
});
export default (Cloud);
