/**
 * @description 路由匹配错误跳转到该页面
 */
import React from 'react';
import { Result, Button } from "antd";

export default function ({ history }) { 
    return (
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={<Button type="primary" onClick={()=>history.push('/')}>回到首页</Button>}
      />
    );
}