/**
 * @description 产品2300
 */
import React, { memo, useCallback,useState,useMemo} from "react";
import { Link } from "react-router-dom";
import icon1 from '../../../img/product/icon2300one.png';
import icon2 from '../../../img/product/icon2300two.png';
import icon3 from '../../../img/product/icon2300third.png';
import TopMenu from '../../../component/header.jsx';
import Footer from '../../../component/footer.jsx';
import Customer from '../../../component/customer.jsx';
import "./hardware.css";
import { Layout } from 'antd';
const { Content } = Layout;
const Hardware2300 = memo((props) => {
const [index, setIndex] = useState(5);

const onChange = useCallback((val) => {
	setIndex(val)
},[])	

return (
	<Layout style={{height:'100%'}}>
		<Content style={{height:'100%'}}>
			<TopMenu />
			<div className='subpage'>
				<header  className='hardware2300-header'>
					<h5>工业智能硬件产品</h5>
					<p>对标国际一流系统，参照国际API 670标准设计广泛应用于钢铁、石油、化工等行业的生产设备振动温度等监控机器学习准确识别传感器故障，减少异常停机AI模型边缘部署，自适应调整警报阀值，减少故障漏报误报。</p>
				</header>
				<div className='hardware2300-section'>
					<div  className='hardware2300-second'>
						<div  className='hardware2300-second-left'></div>
						<div  className='hardware2300-second-right'>
							<h2>Data Watch 2700  </h2>
							<h5>凯奥思工业边缘智能终端</h5>
							<p>Data Watch 2700是紧凑型数据采集和边缘计算终端，安装在设备周边，接入各种传感器，采集工业现场的振动位移、速度、加速度、噪声、温度、压力、液位、阀门位置等不同类型的信号。在设备边缘，利用智能终端强大的并行计算能力，进行信号处理、特征提取和智能识别，将计算后的静态特征数据和动态原始数据传输到云端或PLC、SCADA等系统，供进一步数据分析使用。</p>
							<p>Data Watch 2700技术参数符合国际石油协会API670标准。可以为大中型的旋转机械、往复机械、以及其他各种工业现场设备提供连续的在线参数测量，故障诊断和预测服务。特别适合于电力、冶金、石化等行业的现场使用。</p>
						</div>
					</div>
					<div  className='hardware2300-third'>
						<ul>
							<li>
								<img src={icon1}/>
								<div>
									<h5>高性价比</h5>
									<p>系统为双电源供电，当一路电源故障时，另外一路电源可以自动切入，提高系统的可靠性</p>
								</div>
							</li>
							<li>
								<img src={icon2}/>
								<div>
									<h5>紧凑型</h5>
									<p>系统结构紧凑、坚固，接线、安装、维修、更换非常方便</p>
								</div>
							</li>
							<li>
								<img src={icon3}/>
								<div>
									<h5>固定通道</h5>
									<p>支持18个不同种类通道的同步采样、信号处理、数据记录和传输</p>
								</div>
							</li>
						</ul>
					</div>
				</div>
				<div className='hardware2300-four'>
					<h1>产品参数</h1>
					<ul>
						<li><strong>产品型号</strong>Data Watch 2700</li>
						<li><strong>产品用途</strong>重要辅机动设备</li>
						<li><strong>技术参数</strong>ARM 双核处理器、2GB内存、32GB储存</li>
						<li><strong>FPGA</strong>Zynq 7030</li>
						<li><strong>通道数</strong>6,12,18</li>
						<li><strong>网络通讯</strong>Ethernet WIFI Bluetooth</li>
						<li><strong>安装方式</strong>设备周边 导轨/壁挂安装</li>
					</ul>
				</div>
				<Customer />
				<Footer />
			</div>
		</Content>
    </Layout>
  	);
});
export default (Hardware2300);
