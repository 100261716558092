/**
 * @description 产品
 */
import React, { memo, useCallback,useState,useMemo} from "react";
import qiche6 from '../../img/qiche6.jpg';
import data1900 from '../../img/product/data1900.png';
import data2700 from '../../img/data2700.png';
import sensor from '../../img/product/node.png';
import qiche1 from '../../img/homeq12.jpg';
import qiche2 from '../../img/qiche2.jpg';
import qiche3 from '../../img/qiche3.jpg';
import qiche4 from '../../img/qiche4.jpg';
import qiche5 from '../../img/qiche5.jpg';
import qiche_jg from '../../img/qiche_jg.jpg';
import customer1 from '../../img/zs_tp6.jpg';
import customer2 from '../../img/zs_tp7.jpg';
import customer3 from '../../img/zs_tp8.jpg';
import customer4 from '../../img/zs_tp9.jpg';
import customer5 from '../../img/zs_tp10.jpg';

import TopMenu from '../../component/header.jsx';
import Footer from '../../component/footer.jsx';
import { Layout,Tabs} from 'antd';
import "./solution.css";
const { Content } = Layout;
const { TabPane } = Tabs;

const ElectricSolution = memo((props) => {	

return (
	<Layout style={{height:'100%'}}>
		<Content style={{height:'100%'}}>
			<TopMenu />
			<div className='subpage'>
				<header  className='electric-solution-header'>
					<h5>发电行业解决方案</h5>
					<p>凯奥思工业互联网平台，包含联接管理平台、工业智能体、工业应用平台三大部分。定位于做企业增量的智能决策系统，实现业务在云上敏捷开发，边缘可信运行。赋能行业合作伙伴深耕工业核心业务流，持续释放潜在业务价值。</p>
				</header>
				<div className='section'>
					<div className='solution-section'>
						<h1 className='section-title'>案例背景</h1>
						<img src={qiche1} />
						<ul>
							<li><strong>传统的人工巡检耗时耗力,80%时间用于数据收集,仅20%时间用于数据分析。</strong></li>
							<li><strong>停机发生时,没有详细数据,难以定位事故原因,不能避免下次事故发生。</strong></li>
							<p>发电设备停机给企业造成严重的损失。通过关键设备在线监控,核心设备运行数据随时可见,更多时间用于数据分析和故障诊断,提高设备在线率,降低备件成本。项目实施第三年,投入回报显著增加,第四年开始为企业节约130%的维护预算。</p>
						</ul>
					</div>
				</div>
				<div className='interlace-section'>
					<div className='solution-second-section'>
						<h1 className='section-title'>解决方案</h1>
						<ul>
							<li>
								<img src={qiche2} />
								<p>使用基于FPGA的智能硬件,采集涡轮机和压缩机等重要资产,以及泵、电机等辅助型设备的振动、温度、油压等参数。</p>
							</li>
							<li>
								<img src={qiche3} />
								<p>安装了两千多个智能硬件,增加了三万多个传感器,对一万多台设备进行在线监控。</p>
							</li>
							<li>
								<img src={qiche4} />
								<p>在六十多个发电厂安装数据服务器,以采集、存储和组各种来源的原始数据和分析结果。</p>
							</li>
							<li>
								<img src={qiche5} />
								<p>通过监控中心的数人小组,即可对全集团的核心设备数据进行分析,对故障进行诊断和预测。</p>
							</li>
						</ul>
					</div>
				</div>
				<div className='section'>
					<div className='solution-third-section'>
						<h1 className='section-title'>方案架构</h1>
						<img src={qiche_jg} />
					</div>
				</div>
				<div className='interlace-section'>
					<div className='four-interlace-section'>
					<h1 className='section-title'>产品支撑</h1>
						<Tabs type="card" tabPosition="left">
							<TabPane tab="工业智能边缘终端3500" key="1">
								<div className='img'><img src={qiche6} /></div>
								<ul className='ul'>
									<li><h4>DATA WATCH 3500</h4></li>
									<li>产品特点:  高性能 大通道数 灵活配置</li>
									<li>产品用途:  大型旋转或往复式核心动设备</li>
									<li>典型场景:  大中型空分设备汽轮机透平机械大中型水轮机发电机组等</li>
								</ul>
							</TabPane>
							<TabPane tab="工业智能边缘终端2700" key="2">
								<div className='img'><img src={data2700} /></div>
								<ul className='ul'>
									<li><h4>DATA WATCH 2700</h4></li>
									<li>产品特点:  高性价比、紧凑型、灵活配置</li>
									<li>产品用途:  重要辅机动设备</li>
									<li>典型场景:  多轴联动机床，冷却塔风扇泵；风机；鼓风机；马达；磨煤机；离心机；混合器；搅拌机；空压机；中型往复式压缩机</li>
								</ul>
							</TabPane>
							<TabPane tab="工业智能边缘终端1900" key="3">
								<div className='img'><img src={data1900} /></div>
								<ul className='ul'>
									<li><h4>DATA WATCH 1900</h4></li>
									<li>产品特点:  高性价比、紧凑型、固定通道</li>
									<li>产品用途:  一般用途的旋转、往复动设备</li>
									<li>典型场景:  中高端机床，加工中心，泵机、小型电动机、粉碎机、小型旋转式或往复式压缩机，小型水力涡轮，离心机</li>
								</ul>
							</TabPane>
							<TabPane tab="工业智能传感器" key="4">
								<div className='img'><img src={sensor} /></div>
								<ul className='ul'>
									<li><h4>Chaos Data Node</h4></li>
									<li>产品特点:  Wifi+Zigbee无线传输、三年以上寿命</li>
									<li>产品用途:  工业现场不适宜布置传感器线缆</li>
									<li>典型场景:  钢铁和汽车制造行业生产流水线上电机减速机轴承座等</li>
									{/* <li><a>了解更多》</a></li> */}
								</ul>
							</TabPane>
						</Tabs>
					</div>
				</div>
				<div className='section'>
					<div className='customer-img'>
						<h1 className='section-title'>典型客户</h1>
						<img src={customer1} />
						<img src={customer2} />
						<img src={customer3} />
						<img src={customer4} />
						<img src={customer5} />
					</div>
				</div>
				<Footer />
			</div>
		</Content>
    </Layout>
  	);
});
export default (ElectricSolution);
