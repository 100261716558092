export default {
    "header.home": "Home",
    "header.product": "Product",
    "header.solution": "Solution",
    "header.software": "Software",
    "header.news": "News",
    "header.about": "About",
    "header.agent": "Agent",
    "footer.contact": "Contact",  
    "footer.follow": "Follow",  
};
