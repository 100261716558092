/**
 * @description 产品
 */
import React, { memo, useCallback,useState,useMemo} from "react";
import customer6 from '../../img/dianhhm.png';
import zsone from '../../img/zs_ico5.jpg';
import zstwo from '../../img/zs_ico6.jpg';
import zsthree from '../../img/zs_ico7.jpg';
import agent1 from '../../img/agent1.jpg';
import agent2 from '../../img/agent2.jpg';
import agent3 from '../../img/agent3.jpg';
import agent4 from '../../img/agent4.jpg';
import agent5 from '../../img/agent5.jpg';
import customer1 from '../../img/zs_tp6.jpg';
import customer2 from '../../img/zs_tp7.jpg';
import customer3 from '../../img/zs_tp8.jpg';
import customer4 from '../../img/zs_tp9.jpg';
import customer5 from '../../img/zs_tp10.jpg';
import solution1 from '../../img/zs_ico1.jpg';
import solution2 from '../../img/zs_ico2.jpg';
import solution3 from '../../img/zs_ico3.jpg';
import solution4 from '../../img/zs_ico4.jpg';
import TopMenu from '../../component/header.jsx';
import Footer from '../../component/footer.jsx';
import { Layout,Tabs} from 'antd';
import "./agent.css";
const { Content } = Layout;
const { TabPane } = Tabs;

const Agent = memo((props) => {

const [index, setIndex] = useState(5);

const onChange = useCallback((val) => {
	setIndex(val)
},[])	

return (
	<Layout style={{height:'100%'}}>
		<Content style={{height:'100%'}}>
			<TopMenu />
			<div className='subpage'>
				<header  className='agent-header'></header>
				<div className='section'>
					<div className='agent-section'>
						<div className='first-top'>
							<h3>———— 工业智造解决方案 ————</h3>
							<div>
								<h1>动设备预测性运维管理</h1>
								<p>智能制造发展进程中关键基石</p>
							</div>
						</div>
						<ul className='first-ul'>
							<li>
								<div><img src={zsone} /></div>
								<span>精细化解读生产设备运行状态，实时预测性汇报设备故障。</span>
							</li>
							<li>
								<div><img  src={zstwo} /></div>
								<span>准确掌控生产设备全生命周期，实现生产精益管理。</span>
							</li>
							<li>
								<div><img  src={zsthree} /></div>
								<span>为工业制造企业打造本地化知识体系，迈向智能制造新时代。</span>
							</li>
						</ul>
					</div>
				</div>
				<div className='interlace-section'>
					<div className='agent-section second-section'>
						<h3 className='agent-title'>———— 工业智造解决方案 ————</h3>
						<ul>
							<li>
								<img  src={solution1} />
								<span>国家政策支持应用企业</span>
							</li>
							<li>
								<img  src={solution2} />
								<span>40余年2000000+台设备运行经验与积累</span>
							</li>
							<li>
								<img  src={solution3} />
								<span>全球顶尖科学家团队</span>
							</li>
							<li>
								<img  src={solution4} />
								<span>技术对标国际品牌，成本降低85%</span>
							</li>
						</ul>
					</div>
				</div>
				<div className='section'>
					<div className='agent-section third-section'>
						<h3 className='agent-title'>———— 目标行业 ————</h3>
						<ul>
							<li>
								<div className="target-img">
									<img src={agent1} />
								</div>
								<div className="target-second">
									<p></p>
									<span>钢铁行业</span>
								</div>
							</li>
							<li>
								<div className="target-one">
									<p></p>
									<span>钢铁行业</span>
								</div>
								<div className="target-img">
									<img src={agent2} />
								</div>
							</li>
							<li>
								<div className="target-img">
									<img src={agent3} />
								</div>
								<div className="target-second">
									<p></p>
									<span>钢铁行业</span>
								</div>
							</li>
							<li>
								<div className="target-one">
									<p></p>
									<span>钢铁行业</span>
								</div>
								<div className="target-img">
									<img src={agent4} />
								</div>
							</li>
							<li>
								<div className="target-img">
									<img src={agent5} />
								</div>
								<div className="target-second">
									<p></p>
									<span>钢铁行业</span>
								</div>
							</li>
						</ul>
					</div>
				</div>
				<div className='customer-section'>
					<div className='agent-section'>
						<h3 className='customer-title'>———— 当前已落地客户 ————</h3>
						<div className='customer-contact'>
							<img className='customer-img' src={customer1} />
							<img className='customer-img' src={customer2} />
							<img className='customer-img' src={customer3} />
							<img className='customer-img' src={customer4} />
							<img className='customer-img' src={customer5} />
						</div>
						<p><img src={customer6} /></p>
					</div>
				</div>
				<Footer />
			</div>
		</Content>
    </Layout>
  	);
});
export default (Agent);
