/**
 * @description 软件产品EAM
 */
import React, { memo, useCallback,useState,useMemo} from "react";
import { Link } from "react-router-dom";
import second1 from '../../../img/ruanj2.jpg';
import second2 from '../../../img/ruanj3.jpg';
import second3 from '../../../img/ruanj4.jpg';
import second4 from '../../../img/ruanj5.jpg';
import customer1 from '../../../img/zs_tp6.jpg';
import customer2 from '../../../img/zs_tp7.jpg';
import customer3 from '../../../img/zs_tp8.jpg';
import customer4 from '../../../img/zs_tp9.jpg';
import customer5 from '../../../img/zs_tp10.jpg';
import TopMenu from '../../../component/header.jsx';
import Footer from '../../../component/footer.jsx';
import { Layout,Tabs} from 'antd';
import "./software.css";
const { Content } = Layout;
const { TabPane } = Tabs;

const Eam = memo((props) => {

return (
	<Layout style={{height:'100%'}}>
		<Content style={{height:'100%'}}>
			<TopMenu />
			<div className='subpage'>
				<header  className='software-header'>
					<h5>工业智能软件产品</h5>
					<p>对标国际一流系统，参照国际API 670标准设计广泛应用于钢铁、石油、化工等行业的生产设备振动温度等监控机器学习准确识别传感器故障，减少异常停机AI模型边缘部署，自适应调整警报阀值，减少故障漏报误报</p>
				</header>
				<div className='one-section'>
					<div className='software-section'>
						<ul className='top'>
							<li className='eam'></li>
							<li>
								<h1>设备运维管理系统 EAM </h1>
								<p>EAM是Enterprise Asset Management 的缩写，EAM系统是指企业资产管理系统。EAM系统是在资产比重较大的企业，在资产建设、维护中减少维护成本，提高资产运营效率，通过现代信息技术减少停机时间，增加产量的一套企业资源计划系统。EAM企业资产管理系统即是面向资产密集型企业的信息化解决方案的总称，也是以企业资产管理为核心的商品化应用软件。</p>
                                <p>它主要包括：基础管理、工单管理、预防性维护管理、资产管理、作业计划管理、安全管理、库存管理、采购管理、报表管理、检修管理、数据采集管理等基本功能模块，以及工作流管理、决策分析等可选模块。</p>
                                <p>EAM以资产模型、设备台帐为基础，强化成本核算的管理思想，以工单的创建、审批、执行、关闭为主线，合理、优化地安排相关的人、财、物资源，将传统的被动检修转变为积极主动的预防性维修，与实时的数据采集系统集成，可以实现预防性维护。通过跟踪记录企业全过程的维护历史活动，将维修人员的个人知识转化为企业范围的智力资本。集成的工业流程与业务流程配置功能，使得用户可以方便地进行系统的授权管理和应用的客户化改造工作。</p>
                            </li>
						</ul>
						<ul className='cloud-bottom'>
							<li>
								<img src={second1} />
								<p>集成Tensorflow、Pytorch，Keras 深度学习框架</p> 
							</li>
							<li>
								<img src={second2} />
								<p>20多个先进机器学习算法，自动选择最优预测模型</p> 
							</li>
							<li>
								<img src={second3} />
								<p>50多种风机、泵相关故障机理模型，实现故障智能诊断</p> 
							</li>
						</ul>
					</div>
				</div>
				<div className='software-customer-img'>
					<h1 className='section-title'>典型客户</h1>
					<img src={customer1} />
					<img src={customer2} />
					<img src={customer3} />
					<img src={customer4} />
					<img src={customer5} />
				</div>
				<Footer />
			</div>
		</Content>
    </Layout>
  	);
});
export default (Eam);
