/**
 * @description 无线传感器详情
 */
import React, { memo, useCallback,useState,useEffect} from "react";
import { axiosInstance,baseUrl} from "../../utils/axiosInstance";
import { Link } from "react-router-dom";
import ta102 from '../../img/ta102.jpg';
import TopMenu from '../../component/header.jsx';
import Footer from '../../component/footer.jsx';
import { Layout } from 'antd';
import "./nodeItem.css";
const { Content } = Layout;

const Product = memo((props) => {
	const [index, setIndex] = useState(5);
	const [newList, setNewList] = useState([]);
	useEffect(() => {
		axiosInstance.get(`/product/page/1/100/CHAOS`)
		.then(res => {
			if (res.data.success) {
				setNewList(res.data.content)
			}else{
				setNewList([])
			}
		});
	}, [index]);
	
	const onChange = useCallback((val) => {
		setIndex(val)
	},[])	

return (
	<Layout style={{height:'100%'}}>
		<Content style={{height:'100%'}}>
			<TopMenu />
			<div className='subpage'>
				<header  className='product-node-header'>
					<h5>无线传感器</h5>
					<p>无线传输，安装便捷。多种特征及原始数据，便于问题分析。3年使用寿命，电池可更换。满足精密诊断要求，密封、防尘、防污。</p>
				</header>
				<div className='section'>
					<div className='prouct-first-section'>
						<h1 className='section-title'>详细介绍</h1>
						<ul  className='data-node-img'>
							<li  className='one'></li>
							<li  className='two'></li>
						</ul>
					</div>
				</div>
				<div className='interlace-section'>
					<div className='prouct-second-section'>
						<h1 className='section-title'>产品展示</h1>
						<ul>
							{newList.length>0?	newList.map((item,index)=>{
							return	<Link 
							to='/node/item' 
							key={item.id} 
							onClick={()=>{
								console.log(item)
								localStorage.setItem('nodeItem',JSON.stringify(item))
							}}
							>
								<li>
									<img src={baseUrl+item.imgPath} />
									<h5>{item.name}</h5>
									<p>{item.description}</p>
								</li>
							</Link>
							}):null}
						</ul>
					</div>
				</div>
				<Footer />
			</div>
		</Content>
    </Layout>
  	);
});
export default (Product);