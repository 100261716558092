/**
 * @description 首页
 */
import React, { memo, useEffect,useCallback,useState,useMemo,useRef} from "react";
import { axiosInstance,baseUrl} from "../../utils/axiosInstance";
import intl from 'react-intl-universal';
import { Link } from "react-router-dom";
import logoOne from '../../img/logo.png';
import main from '../../img/main.png';
import adress from '../../img/adress.png';
import email from '../../img/email.png';
import phone from '../../img/phone.png';
import phone2 from '../../img/phone2.png';
import about from '../../img/about_bt.png';
import ry from '../../img/ry.png';
import dsj from '../../img/dsj.png';
import wenh from '../../img/wenh.png';
import jrwm from '../../img/jrwm.png';
import homeq11 from '../../img/home/homeq11.jpg';
import homeq12 from '../../img/home/homeq12.jpg';
import homeq21 from '../../img/home/homeq21.jpg';
import homeq22 from '../../img/home/homeq22.jpg';
import homeq31 from '../../img/home/homeq31.jpg';
import homeq32 from '../../img/home/homeq32.jpg';
import homeq41 from '../../img/home/homeq41.jpg';
import homeq42 from '../../img/home/homeq42.jpg';
import homeq51 from '../../img/home/homeq51.jpg';
import productLogo from '../../img/product_bt.png';
import productLeft from '../../img/product_left.jpg';
import productRight from '../../img/product_right.jpg';
import solution from '../../img/Solution_bt.png';
import newslogo from '../../img/news_bt.png';
import contactlogo from '../../img/contact_bt.png';
import homeMap from '../../img/home_map.jpg';
import qrCode from '../../img/qrCode.jpg';
import Header from '../../component/header.jsx';
import { Layout,Carousel,Radio } from 'antd';
import {LeftOutlined,RightOutlined} from '@ant-design/icons';
import "./home.css";
const { Footer,Content } = Layout;
const getWindowSize = () => ({
	innerHeight: window.innerHeight,
	innerWidth: window.innerWidth,
})
const Home = memo((props) => {
const carouselChange = useRef();
const industry = useRef();
const [index, setIndex] = useState(0);
const [companyList, setCompanyList] = useState([]);
const [industryList, setIndustryList] = useState([]);
const [changePage, setChangePage] = useState(1);
const [changeTotalPage, setChangeTotalPage] = useState(1);
const [windowSize, setWindowSize] = useState(getWindowSize())

const handleResize = () => {
	setWindowSize(getWindowSize())
}

useEffect(() => {
	// 监听
	window.addEventListener('resize', handleResize)
	console.log(windowSize)
	// 销毁
	return () => window.removeEventListener('resize', handleResize)
})

useEffect(() => {
	console.log(windowSize,"windowSize")
},[windowSize])

useEffect(() => {
	if(index === 4){
		axiosInstance.get(`/news/overView/1`)
		.then(res => {
			if (res.data.success) {
				setCompanyList(res.data.content)
			}else{
				setCompanyList([])
			}
		});
		axiosInstance.get(`/news/overView/2`)
		.then(res => {
			if (res.data.success) {
				setIndustryList(res.data.content)
			}else{
				setIndustryList([])
			}
		});
	}
	
}, [index]);

const nextChange = useCallback(() => {
	carouselChange.current.slick.slickNext();
},[carouselChange])

const prevChange = useCallback(() => {
	carouselChange.current.slick.slickPrev();
},[carouselChange])

const prevIndustry = useCallback(() => {
	industry.current.slick.slickNext();
},[carouselChange])

const nextIndustry = useCallback(() => {
	industry.current.slick.slickPrev();
},[carouselChange])

const homeMain = useMemo(() => {
    switch (index) {
		case 0:
			return <div className="home-main">
					<img className="home-image" src={main} />
				</div>
			break;
		case 1:
			return <div className="home-one">
				<div className="about">
					<img src={about} />
					<h5 className="title">{intl.get("header.about")}</h5>
					<p>{intl.get("home.about.introduce.one")}</p>
					<p>{intl.get("home.about.introduce.two")}</p>
					<Link to='/about'>{intl.get("home.about.introduce.more")}</Link>
				</div>
				<div className="aboutNav">
					<Link to='/about'>
						<div className="ryzz">
							<img src={ry} />
							<p>{intl.get("home.about.introduce.honor")}</p>
							<span>|</span>
						</div>
						<div className="ryzz">
							<img src={dsj} />
							<p >{intl.get("home.about.introduce.memorabilia")}</p>
							<span>|</span>
						</div>
						<div className="ryzz">
							<img src={wenh} />
							<p >{intl.get("home.about.introduce.corporate")}</p>
							<span>|</span>
						</div>
						<div className="ryzz">
							<img src={jrwm} />
							<p >{intl.get("home.about.introduce.join")}</p>
							<span>|</span>
						</div>
					</Link>
				</div>
			</div>
			break;
		case 2:
			return <div className="solution">
						<div className="center">
							<img src={solution} />
							<h5 className="title">{intl.get("header.solution")}</h5>
							<p>{intl.get("home.solution.introduce")}</p>
						</div>
						<div className="solution_fenl">
							<ul>
								<Link to='/solution/electric'>
									<li>
										<div><img  src={homeq11} /></div>
										<p>{intl.get("home.solution.introduce.chemical")}</p>
										<span></span>
									</li>
								</Link>
								<Link to='/solution/chemistry'>
									<li>
										<div><img  src={homeq22} /></div>
										<p>{intl.get("home.solution.introduce.electric")}</p>
										<span></span>
									</li>
								</Link>
								<Link to='/solution/steel'>
									<li>
										<div><img  src={homeq31} /></div>
										<p>{intl.get("home.solution.introduce.steel")}</p>
										<span></span>
									</li>
								</Link>
								<Link to='/solution/car'>
									<li>
										<div><img  src={homeq42} /></div>
										<p>{intl.get("home.solution.introduce.car")}</p>
										<span></span>
									</li>
								</Link>
								<Link to='/solution/track'>
									<li>
										<div><img  src={homeq51} /></div>
										<p>{intl.get("home.solution.introduce.track")}</p>
										<span></span>
									</li>
								</Link>
							</ul>
						{/* <div className="solution_next">
							<div className="next_left"><Link to=''><LeftOutlined /></Link></div>
							<div className="next_right"><Link to=''><RightOutlined /></Link></div>
						</div> */}
					</div>
				</div>
			break;
		case 3:
			return <div className="productContiner">
						<div className="center">
							<img src={productLogo} />
							<h5 className="title">{intl.get("header.product")}</h5>
							<div className="product">
								<div className="main">
									<img src={productLeft} />
									<h5>{intl.get("home.solution.hardware.title")}</h5>
									<p>{intl.get("home.solution.hardware.p")}</p>
								</div>
								<div className="main">
									<img src={productRight} />
									<h5>{intl.get("home.solution.software.title")}</h5>
									<p>{intl.get("home.solution.hardware.p")}</p>
								</div>
							</div>
						</div>
					</div>
			break;
		case 4:
			return <div className="newsContiner">
						<div className="center">
							<img src={newslogo} />
							<h5 className="title">新闻动态</h5>
							<div className="news">
								<div className="news-tab">
									<Carousel ref={carouselChange} >
										{companyList.length>0?
											companyList.map((item,index)=>{
											return <div 
											className="choose"
											onClick={()=>{
												localStorage.setItem('newsItem',JSON.stringify(item))
											}}
											key={item.id} 
											>	
											{companyList.length>1?
											<>
												<b className="choose-left" onClick={prevChange}>
												<LeftOutlined />
												</b>
												<b  className="choose-right"onClick={nextChange}>
												<RightOutlined />
												</b>
											</>:null}
											<Link to='newsItem' >
												<div className="news-title">公司新闻</div>
												<h5>{item.title}</h5>
												<p>{item.newsOverView}</p>
												<div className="time">{item.addTime}</div>
											</Link>
											</div>
										}):null}
									</Carousel>
								</div>
								<div className="news-tab">
									<Carousel autoplay ref={industry}>
										{industryList.length>0?
											industryList.map((item,index)=>{
											return <div 
											className="choose"
											key={item.id} 
											onClick={()=>{
												localStorage.setItem('newsItem',JSON.stringify(item))
											}}
											>	
												{industryList.length>1?
												<>
													<b className="choose-left" onClick={prevIndustry}>
														<LeftOutlined />
													</b>
													<b  className="choose-right"onClick={nextIndustry}>
														<RightOutlined />
													</b>
												</>:null}
												<Link to='newsItem' key={item.id} >
													<div className="news-title">行业新闻</div>
													<h5>{item.title}</h5>
													<p>{item.newsOverView}</p>
													<div className="time">{item.addTime}</div>
												</Link>
											</div>
										}):null}
									</Carousel>
								</div>
								{/* <ul>
									<li className="small-next" >
										<b onClick={()=>{pageChage('left')}}><LeftOutlined /></b>
										<b onClick={()=>{pageChage('right')}}><RightOutlined /></b>
									</li>
									<li className="next_left" onClick={()=>{pageChage('left')}}><LeftOutlined /></li>
									{newList.length>0? newList.map((item,index)=>{
										return <li className="choose" key={item.id}>
											<div className="news-title">公司新闻</div>
											<h5>{item.brand}</h5>
											<p>{item.description}</p>
											<div className="time">2020-08-01</div>
										</li>
									}):null}
									<li 
									className="next_right"
									onClick={()=>{pageChage('right')}}
									><RightOutlined /></li>
								</ul>
								<ul>
									<li className="small-next" >
										<b onClick={()=>{pageChage('left')}}><LeftOutlined /></b>
										<b onClick={()=>{pageChage('right')}}><RightOutlined /></b>
									</li>
									<li className="next_left" onClick={()=>{pageChage('left')}}><LeftOutlined /></li>
									{newList.length>0? newList.map((item,index)=>{
										return <li className="choose" key={item.id}>
											<div className="news-title">行业新闻</div>
											<h5>{item.brand}</h5>
											<p>{item.description}</p>
											<div className="time">2020-08-01</div>
										</li>
									}):null}
									<li 
									className="next_right"
									onClick={()=>{pageChage('right')}}
									><RightOutlined /></li>
								</ul> */}
							</div>
						</div>
					</div>
			break;
		case 5:
			return <div className="contactContiner">
						<div className="center">
							<img src={contactlogo} />
							<h5 className="title">联系我们</h5>
							<div className="contact">
								<img className="map" src={homeMap} />
								{/* <div className="map"></div> */}
								<div className="address">
									<div className="left">
										<p>南京凯奥思技术股份有限公司</p>
										<ul>
											<li><img src={phone2} /><span>地址：南京市雨花台区宁双路19号软件谷云密城J栋7楼</span></li>
											<li><img src={adress} /><span>025-83132381</span></li>
											<li><img src={email} /><span>contact@njchaos.com</span></li>
										</ul>
									</div>
									<div className="right">
										<img src={qrCode} />
										<p>关注凯奥思公众号</p>
									</div>
								</div>
							</div>
						</div>
					</div>
			break;
		default:
			break;
	}
}, [index,companyList,industryList]);

const onChange =useCallback((val)=>{
	setIndex(val)
},[]);

useEffect(() => {
    let ws = setInterval(() => {
		let newIndex = index+1;
		setIndex(newIndex>5? 0:newIndex)
    }, 10000);
    return () => {
      clearInterval(ws);
    };
}, [index]);

const handleScroll = useCallback((e)=>{
	if(e === null) return;
    if (e.nativeEvent.deltaY <= 0) {
		let newIndex = index-1;
		setIndex(newIndex<0? 5:newIndex)
    } else{
		let newIndex = index+1;
		setIndex(newIndex>5? 0:newIndex)
	}
},[index]);

return (
	<Layout 
	className="home-layout" 
	onWheel={e => handleScroll(windowSize.innerWidth>1024?e:null)}
	>
		<Content className="home-layout">
			<Header />
			{homeMain}
			<p className="home-change">
				{[0,1,2,3,4,5].map((item,i)=>{
				return	<span 
					key={i}
					className={index===i? "active":'' }
					onClick={()=>{onChange(i)}}
					>
					</span>
				})}
			</p>
			<div className="home-left-footer">
				<p className="active">
					<img src={phone}  className="icon"/>
					|  025-83132381
				</p>
				<p>
					<img src={adress}  className="icon"/>
					地址：南京市雨花台区宁双路19号软件谷云密城J栋7楼
				</p>
				<p>
					<img src={email}  className="icon"/>
					contact@njchaos.com
				</p>
			</div>
			<p className="home-footer">
				Copyright 2017-2018 南京凯奥思数据技术有限公司. 版权所有 苏ICP备18005519号
			</p>
		</Content>
    </Layout>
  	);
});
export default (Home);
